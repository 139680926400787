import React, {Fragment} from 'react'; // eslint-disable-line no-unused-vars
import {alpha} from '@theme-ui/color';
import PropTypes from 'prop-types';

import Box from '../Box';
import {FlexCol, FlexRow} from '../Flex';
import Text from '../Text';
import {IconRadioChecked, IconRadioUnchecked, IconCheckboxChecked, IconCheckboxUnchecked} from '../../icons';

/**
 * When you need to add some more explanation to controls or want to give them
 * more visual weight use a <InputPanel> instead of the regular `<Radio/>` or `<Checkbox>`
 * component. The component is based on regular radio form elements and accepts
 * html attributes accordingly.
 */
const InputPanel = React.forwardRef(({
    children,
    type,
    title,
    name,
    value,
    defaultChecked,
    description,
    ...restProps
}, ref) => {
    return (
        <Box as="label">
            <Box
                ref={ref}
                as="input"
                type={type}
                defaultChecked={defaultChecked}
                name={name}
                value={value}
                {...restProps}
                sx={{
                    position: 'absolute',
                    opacity: 0,
                    zIndex: -1,
                    width: 1,
                    height: 1,
                    overflow: 'hidden'
                }}
            />
            <Box
                className="radio-box"
                sx={{
                    p: 'sm',
                    bg: 'white',
                    borderWidth: 1,
                    borderStyle: 'solid',
                    borderColor: 'border',
                    borderRadius: 'md',
                    cursor: 'pointer',
                    'input:hover ~ &': {
                        bg: alpha('whisper', 0.05)
                    },
                    'input:focus ~ &': {
                        outline: 0,
                        borderColor: '#63b3ed !important',
                        boxShadow: '0 0 0 3px rgba(66, 153, 225, 0.5)'
                    },
                    'input:checked ~ &': {
                        bg: alpha('primary', 0.1),
                        borderColor: 'primary'
                    },
                    'input:checked:hover ~ &': type === 'radio' ? {} : {
                        bg: alpha('primary', 0.15),
                        borderColor: 'primary'
                    }
                }}
            >
                <FlexRow space="sm" alignY="center">
                    <FlexRow width="content">
                        {type === 'checkbox' ? (
                            <Fragment>
                                <IconCheckboxChecked
                                    sx={{
                                        color: 'border',
                                        display: 'none',
                                        'input:checked ~ .radio-box &': {
                                            color: 'primary',
                                            display: 'block'
                                        }
                                    }}
                                />
                                <IconCheckboxUnchecked
                                    sx={{
                                        display: 'block',
                                        color: 'border',
                                        'input:checked ~ .radio-box &': {
                                            display: 'none'
                                        }
                                    }}
                                />
                            </Fragment>
                        ) : (
                            <Fragment>
                                <IconRadioChecked
                                    sx={{
                                        color: 'border',
                                        display: 'none',
                                        'input:checked ~ .radio-box &': {
                                            color: 'primary',
                                            display: 'block'
                                        }
                                    }}
                                />
                                <IconRadioUnchecked
                                    sx={{
                                        display: 'block',
                                        color: 'border',
                                        'input:checked ~ .radio-box &': {
                                            display: 'none'
                                        }
                                    }}
                                />
                            </Fragment>
                        )}
                    </FlexRow>
                    <FlexRow>
                        {children || (
                            <FlexCol space="xxs">
                                <Text bold={true}>
                                    {title}
                                </Text>
                                {description && (
                                    <Text color="whisper">
                                        {description}
                                    </Text>
                                )}
                            </FlexCol>
                        )}
                    </FlexRow>
                </FlexRow>
            </Box>
        </Box>
    );
});

InputPanel.displayName = 'InputPanel';

InputPanel.propTypes = {
    /** Input type  */
    type: PropTypes.oneOf(['radio', 'checkbox']).isRequired,
    /** when present, children will be used instead of the title and description */
    children: PropTypes.node,
    /** title of the panel */
    title: PropTypes.string,
    /** description below the title */
    description: PropTypes.string,
    /** group name */
    name: PropTypes.string,
    value: PropTypes.string,
    /** controls wether this panel should be checked by default */
    defaultChecked: PropTypes.bool
};

export default InputPanel;
