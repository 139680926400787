import React, {useCallback, useMemo} from 'react'; // eslint-disable-line no-unused-vars

import {useThemeUI} from 'theme-ui';
import PropTypes from 'prop-types';
import {dependencyKey} from '../../utils';
import {useSpaceMinusBorder, useSpaceDivideBy} from '../../hooks';

import Box from '../Box';
import Tooltip from '../Tooltip';

const useCoreButtonStyles = ({
    label,
    showLabel,
    title,
    showTitle,
    iconLeft,
    iconRight,
    size,
    color,
    pill,
    fullWidth,
    isDisabled
}) => {
    const {theme} = useThemeUI();

    const spaceMinusBorder = useSpaceMinusBorder(theme);
    const spaceDivideBy = useSpaceDivideBy(theme);

    return useMemo(() => {
        // Create array of resolved breakpoints
        const resolvedBreakpoints = (() => {
            const sizeBreakpoints = Array.isArray(size)
                ? size
                : [size];

            const showLabelBreakpoints = Array.isArray(showLabel)
                ? showLabel
                : [showLabel];

            const showTitleBreakpoints = Array.isArray(showTitle)
                ? showTitle
                : [showTitle];

            const length = Math.max(sizeBreakpoints.length, showLabelBreakpoints.length, showTitleBreakpoints.length);

            const resolved = [];

            for (let i = 0; i < length; i++) {
                const shouldShowLabel = label === null || label === undefined
                    ? false
                    : showLabelBreakpoints[Math.min(i, showLabelBreakpoints.length - 1)];

                const shouldShowTitle = title === null || title === undefined
                    ? false
                    : showTitleBreakpoints[Math.min(i, showTitleBreakpoints.length - 1)];

                resolved.push({
                    size: sizeBreakpoints[Math.min(i, sizeBreakpoints.length - 1)],
                    sizeKey: shouldShowLabel ? 'label' : 'iconOnly',
                    showLabel: shouldShowLabel,
                    showTitle: shouldShowTitle
                });
            }

            return resolved;
        })();

        const sizes = {
            'xs': {
                iconOnly: {
                    size: 3,
                    py: spaceMinusBorder(spaceDivideBy(3, 2), 1),
                    px: spaceMinusBorder(spaceDivideBy(3, 2), 1)
                },
                label: {
                    size: 3,
                    fontSize: 0,
                    fontWeight: 'bold',
                    lineHeight: theme.space[3],
                    py: spaceMinusBorder(spaceDivideBy(3, 2), 1),
                    px: spaceMinusBorder(2, 1)
                }
            },
            'xs+': {
                iconOnly: {
                    size: 4,
                    py: spaceMinusBorder(1, 1),
                    px: spaceMinusBorder(1, 1)
                },
                label: {
                    size: 4,
                    fontSize: 1,
                    fontWeight: 'bold',
                    lineHeight: theme.space[4],
                    py: spaceMinusBorder(1, 1),
                    px: spaceMinusBorder(spaceDivideBy(5, 2), 1)
                }
            },
            'sm': {
                iconOnly: {
                    size: 4,
                    py: spaceMinusBorder(2, 1),
                    px: spaceMinusBorder(2, 1)
                },
                label: {
                    size: 4,
                    fontSize: 1,
                    fontWeight: 'bold',
                    lineHeight: theme.space[4],
                    py: spaceMinusBorder(2, 1),
                    px: spaceMinusBorder(spaceDivideBy(5, 2), 1)
                }
            },
            'sm+': {
                iconOnly: {
                    size: 6,
                    py: spaceMinusBorder(1, 1),
                    px: spaceMinusBorder(1, 1)
                },
                label: {
                    size: 6,
                    fontSize: 2,
                    fontWeight: 'bold',
                    lineHeight: theme.space[6],
                    py: spaceMinusBorder(1, 1),
                    px: spaceMinusBorder(3, 1)
                }
            },
            'md': {
                iconOnly: {
                    size: 6,
                    py: spaceMinusBorder(2, 1),
                    px: spaceMinusBorder(2, 1)
                },
                label: {
                    size: 6,
                    fontSize: 2,
                    fontWeight: 'bold',
                    lineHeight: theme.space[6],
                    py: spaceMinusBorder(2, 1),
                    px: spaceMinusBorder(3, 1)
                }
            },
            'md+': {
                iconOnly: {
                    size: 8,
                    py: spaceMinusBorder(1, 1),
                    px: spaceMinusBorder(1, 1)
                },
                label: {
                    size: 8,
                    fontSize: 3,
                    fontWeight: 'bold',
                    lineHeight: theme.space[8],
                    py: spaceMinusBorder(1, 1),
                    px: spaceMinusBorder(3, 1)
                }
            },
            'lg': {
                iconOnly: {
                    size: 8,
                    py: spaceMinusBorder(2, 1),
                    px: spaceMinusBorder(2, 1)
                },
                label: {
                    size: 8,
                    fontSize: 3,
                    fontWeight: 'bold',
                    lineHeight: theme.space[8],
                    py: spaceMinusBorder(2, 1),
                    px: spaceMinusBorder(3, 1)
                }
            },
            'lg+': {
                iconOnly: {
                    size: 10,
                    py: spaceMinusBorder(1, 1),
                    px: spaceMinusBorder(1, 1)
                },
                label: {
                    size: 10,
                    fontSize: 4,
                    fontWeight: 'bold',
                    lineHeight: theme.space[10],
                    py: spaceMinusBorder(1, 1),
                    px: spaceMinusBorder(3, 1)
                }
            }
        };

        const sizeStyles = {
            fontSize: resolvedBreakpoints.map(({size}) => (sizes[size] || sizes.md).label.fontSize),
            fontWeight: resolvedBreakpoints.map(({size}) => (sizes[size] || sizes.md).label.fontWeight),
            lineHeight: resolvedBreakpoints.map(({size}) => (sizes[size] || sizes.md).label.lineHeight),
            px: resolvedBreakpoints.map(({size, sizeKey}) => (sizes[size] || sizes.md)[sizeKey].px),
            py: resolvedBreakpoints.map(({size, sizeKey}) => (sizes[size] || sizes.md)[sizeKey].py)
        };

        const sharedStyles = {
            alignItems: 'center',
            appearance: 'none',
            borderRadius: 'md',
            borderWidth: theme.border[1],
            borderStyle: 'solid',
            borderColor: 'transparent',
            cursor: 'pointer',
            display: 'inline-flex',
            justifyContent: 'center',
            textDecoration: 'none',
            textAlign: 'center',
            pointerEvents: 'auto'
        };

        const variantStyles = {
            borderRadius: pill === true ? 'full' : 'md',
            width: Array.isArray(fullWidth) ? fullWidth.map(fullWidth => fullWidth ? '100%' : 'unset') : fullWidth ? '100%' : 'unset',
            cursor: isDisabled ? 'not-allowed' : 'pointer'
        };

        const buttonStyles = {
            ...sharedStyles,
            ...sizeStyles,
            ...variantStyles
        };

        const labelStyles = {
            display: resolvedBreakpoints.map(({showLabel}) => showLabel ? 'inline-flex' : 'none'),
            textAlign: 'center',
            flexFlow: 'column',
            width: '100%',
            pl: iconLeft ? 1 : 0,
            pr: iconRight ? 1 : 0
        };

        const iconProps = {
            size: resolvedBreakpoints.map(({size, sizeKey}) => (sizes[size] || sizes.md)[sizeKey].size)
        };

        const iconContainerStyles = {
            minWidth: iconProps.size
        };

        const tooltipProps = {
            disabled: resolvedBreakpoints.map(({showTitle}) => !showTitle)
        };

        return {
            buttonStyles,
            labelStyles,
            iconProps,
            iconContainerStyles,
            tooltipProps
        };
    }, [
        theme,
        spaceMinusBorder,
        dependencyKey({
            label: label === null || label === undefined ? false : true,
            showLabel,
            title: title === null || title === undefined ? false : true,
            showTitle,
            iconLeft: iconLeft ? true : false,
            iconRight: iconRight ? true : false,
            size,
            color,
            pill,
            fullWidth,
            isDisabled
        })
    ]);
};

const CoreButton = React.forwardRef(({
    as,

    label,
    showLabel,

    title,
    showTitle,

    iconLeft,
    iconRight,
    color,
    href,
    pill,
    size,
    fullWidth,

    isDisabled,
    isSelected,

    onClick,

    sx,
    ...restProps
}, ref) => {
    const {
        buttonStyles,
        labelStyles,
        iconProps,
        iconContainerStyles,
        tooltipProps
    } = useCoreButtonStyles({
        label,
        showLabel,
        title,
        showTitle,
        iconLeft,
        iconRight,
        size,
        color,
        pill,
        fullWidth,
        isDisabled
    });

    const IconComponent = iconLeft || iconRight;

    const button = (
        <Box
            ref={ref}
            as={as}
            href={href}
            onClick={isDisabled ? null : onClick}
            {...(as === 'button' ? {disabled: isDisabled} : {})}
            {...restProps}
            sx={{
                ...buttonStyles,
                ...sx
            }}
        >
            {iconLeft && IconComponent ? (
                <Box as="span" sx={iconContainerStyles}>
                    <IconComponent size={iconProps.size} aria-hidden="true"/>
                </Box>
            ) : null}

            {label !== null && label !== undefined && (iconLeft || iconRight) ? (
                <Box
                    as="span"
                    sx={labelStyles}
                >
                    {label}
                </Box>
            ) : (
                label
            )}

            {iconRight && IconComponent ? (
                <Box as="span" sx={iconContainerStyles}>
                    <IconComponent size={iconProps.size} aria-hidden="true"/>
                </Box>
            ) : null}
        </Box>
    );

    if (title) {
        return (
            <Tooltip
                content={title}
                disabled={tooltipProps.disabled}
            >
                {button}
            </Tooltip>
        );
    }

    return button;
});

CoreButton.displayName = 'CoreButton';

CoreButton.propTypes = {
    as: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
    /**
     * `pill` set to `true` will change the button's border radius to 100%
     */
    pill: PropTypes.bool,
    /** Title for tooltips */
    title: PropTypes.string,
    /**
     * Controls the title's display properties across breakpoints
     * */
    showTitle: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.arrayOf(PropTypes.bool)
    ]).isRequired,
    /**
     * The content of the button.
     */
    label: PropTypes.node,
    /**
     * Controls the label's display properties across breakpoints
     * */
    showLabel: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.arrayOf(PropTypes.bool)
    ]).isRequired,
    /**
     * If `true`, the button will be disabled.
     */
    isDisabled: PropTypes.bool,
    /**
     * Icon placed before the children.
     */
    iconLeft: PropTypes.elementType,
    /**
     * Icon placed after the children.
     */
    iconRight: PropTypes.elementType,
    /**
     * when using a href attribute the button will be rendered as an `<a>`
     * element
     */
    href: PropTypes.string,
    /**
     * click handler. when using an onClick attribute the button will be
     * rendered as a `<button>` element
     */
    onClick: PropTypes.func,
    /**
     * The size of the button.
     */
    size: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.oneOf(['xs', 'xs+', 'sm', 'sm+', 'md', 'md+', 'lg', 'lg+'])),
        PropTypes.oneOf(['xs', 'xs+', 'sm', 'sm+', 'md', 'md+', 'lg', 'lg+'])
    ]),
    fullWidth: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.bool),
        PropTypes.bool
    ])
};

export default CoreButton;
