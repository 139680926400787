/** @jsxRuntime classic */
/** @jsx jsx */
/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import { jsx, useThemeUI } from "theme-ui";

const IconAddCircleOutline = ({
  size,
  color,
  sx,
  width,
  height,
  viewBox,
  fill,
  xmln,
  ...props
}) => {
  const { theme } = useThemeUI();
  const iconSize = Array.isArray(size)
    ? size.map((size) => theme.sizes[size] || size)
    : theme.sizes[size] || size;
  const iconColor = Array.isArray(color)
    ? color.map((color) => theme.rawColors[color] || color)
    : theme.rawColors[color] || color;
  const iconStyle = {
    height: iconSize,
    width: iconSize,
    color: iconColor,
    ...sx,
  };
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={iconStyle}
      {...props}
    >
      <path
        d="M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4V7zm-1-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"
        fill="currentColor"
      />
    </svg>
  );
};

IconAddCircleOutline.propTypes = {
  size: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    ),
    PropTypes.number,
    PropTypes.string,
  ]),
  color: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
};
IconAddCircleOutline.defaultProps = {
  size: 6,
  color: "currentColor",
};
export default IconAddCircleOutline;
