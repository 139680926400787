export const dependencyKey = (data) => {
    return JSON.stringify(data, (key, value) => {
        if (value === null || value?.constructor !== Object) {
            return value;
        }
        return Object.keys(value).sort().reduce((s, k) => {
            s[k] = value[k]; return s;
        }, {});
    });
};

// Helper to substract rem & px values
export const substractRem = (remInPx, base, subtract) => {
    // Key to cache the result
    const cacheKey = `${remInPx}; ${base} - ${subtract}`;

    // Read from cache
    const cacheResult = substractRem.cache?.[cacheKey];

    // Return from cache if available
    if (cacheResult) {
        return cacheResult;
    }

    const baseInPx = /rem/.test(base)
        ? parseFloat(base) * remInPx
        : parseFloat(base);

    const substractInPx = /rem/.test(subtract)
        ? parseFloat(subtract) * remInPx
        : parseFloat(subtract);

    if (!substractRem.cache) {
        substractRem.cache = {};
    }

    const result = `${(baseInPx - substractInPx) / remInPx}rem`;

    // Add to cache
    substractRem.cache[cacheKey] = result;

    return result;
};

export const divideRem = (remInPx, base, divide) => {
    // Key to cache the result
    const cacheKey = `${remInPx}; ${base} / ${divide}`;

    // Read from cache
    const cacheResult = divideRem.cache?.[cacheKey];

    // Return from cache if available
    if (cacheResult) {
        return cacheResult;
    }

    const baseInPx = /rem/.test(base)
        ? parseFloat(base) * remInPx
        : parseFloat(base);

    if (!divideRem.cache) {
        divideRem.cache = {};
    }

    const result = `${(baseInPx / divide) / remInPx}rem`;

    // Add to cache
    divideRem.cache[cacheKey] = result;

    return result;

};