import React, {Children} from 'react';
import PropTypes from 'prop-types';

import {IconDropdown, IconDropup} from '../../icons';
import Heading from '../Heading';
import Box from '../Box';

const makeStyles = (variant) => ({
    listStyle: 'none',
    p: 4,
    bg: variant === 'plan' ? 'primary' : undefined,
    cursor: 'pointer',
    '::-webkit-details-marker': {
        display: 'none'
    }
});

export const AccordionItem = ({
    title,
    left,
    right,
    isOpen,
    onClick,
    children,
    variant
}) => {

    return (
        <div open={isOpen}>
            <Box as="div" onClick={onClick} sx={makeStyles(variant)}>
                {/* iOS Safari: we need an additonal element to handle the layout */}
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    {left && (
                        <Box mr="sm" color={variant === 'plan' ? 'textOnDark' : 'whisper'}>
                            {left}
                        </Box>
                    )}
                    <Box sx={{flexGrow: 1, mr: 'sm'}}>
                        <Heading
                            bold={true}
                            as="span"
                            color={variant === 'plan' ? 'textOnDark' : undefined}
                        >
                            {title}
                        </Heading>
                    </Box>
                    {right && (
                        <Box mx="sm" color={variant === 'plan' ? 'textOnDark' : 'whisper'}>
                            {right}
                        </Box>
                    )}
                    {isOpen ? (
                        <IconDropup color={variant === 'plan' ? 'textOnDark' : 'whisper'}/>
                    ) : (
                        <IconDropdown
                            color={variant === 'plan' ? 'textOnDark' : 'whisper'}
                        />
                    )}
                </Box>
            </Box>

            {isOpen &&
        Children.map(children, (child) => {
            return React.cloneElement(child, {
                variant: child.props.variant ?? variant
            });
        })}
        </div>
    );
};

AccordionItem.defaultProps = {
    left: null,
    right: null
};

AccordionItem.propTypes = {
    /* label displayed on accordion item */
    title: PropTypes.string.isRequired,
    /* component displayed to the left of the title */
    left: PropTypes.node,
    /* component displayed to right of the title */
    right: PropTypes.node,
    /* determines if accordion is open or collapsed */
    isOpen: PropTypes.bool,
    /* action triggered on click */
    onClick: PropTypes.func,
    /* variant of the accordion controls the bg and color of the item */
    variant: PropTypes.oneOf(['default', 'plan']),
    /* contents of AccordionItem */
    children: PropTypes.node
};

export default AccordionItem;
