import React, {useCallback, useMemo} from 'react'; // eslint-disable-line no-unused-vars

import {useThemeUI} from 'theme-ui';
import {shade, alpha} from '@theme-ui/color';
import PropTypes from 'prop-types';

import Box from '../Box';
import CoreButton from '../CoreButton';
import LoadingSpinner from '../LoadingSpinner';
import {IconSelected} from '../../icons';

/**
 * Loading spinner where size is converted from icon size
 */
const LoadingIcon = ({size}) => (
    <LoadingSpinner
        size={Array.isArray(size) ? size.map(size => size * 4) : size * 4}
        color="inherit"
    />
);

/**
 * Button is used to trigger actions based on a user's interaction.
 * Automatically uses either an `<a>` or a `<button>` element depending on
 * wether `onClick` / `href` is added.
 */
const Button = React.forwardRef(({
    as,
    children,
    color,
    href,
    isDisabled,
    isSelected,
    isLoading,
    icon,
    loadingText,
    onClick,
    pill,
    rightIcon,
    size,
    selectedColor,
    selectedIcon,
    solidTextColor,
    variant,
    fullWidth,
    sx,
    ...restProps
}, ref) => {
    const {theme} = useThemeUI();

    const _isDisabled = isDisabled || isLoading;

    color = isSelected
        ? selectedColor
        : color;

    color = _isDisabled
        ? [theme.rawColors.disabled]
        : Array.isArray(color)
            ? color.map(color => theme.rawColors[color] || color)
            : color
                ? [theme.rawColors[color] || color]
                : [];

    const variants = {
        transparent: {
            bg: 'rgba(0,0,0,0.7)',
            color: 'white',
            ':hover, :focus': _isDisabled ? null : {
                bg: 'rgba(0,0,0,0.9)'
            }
        },
        solid: {
            bg: color,
            color: solidTextColor,
            ':hover, :focus': _isDisabled
                ? null
                : {
                    bg: color.map(color => shade(color, 0.15)),
                    borderColor: color.map(color => shade(color, 0.15))
                }
        },
        outline: {
            borderColor: color,
            bg: 'transparent',
            color: color,
            ':hover, :focus': _isDisabled
                ? null
                : {
                    bg: color.map(color => alpha(color, 0.1)),
                    color: color.map(color => shade(color, 0.15)),
                    borderColor: color.map(color => shade(color, 0.15))
                }
        },
        ghost: {
            bg: 'transparent',
            color: color,
            ':hover, :focus': _isDisabled
                ? null
                : {
                    bg: color.map(color => alpha(color, 0.1)),
                    color: color.map(color => shade(color, 0.05))
                }
        },
        link: {
            bg: 'transparent',
            color: color,
            p: 0,
            minHeight: 'auto',
            ':hover, :focus': _isDisabled
                ? null
                : {
                    color: color.map(color => shade(color, 0.15)),
                    textDecoration: 'underline'
                }
        },
        selected: {
            bg: variant === 'link' ? null : alpha('black', 0.05),
            color: color,
            p: variant === 'link' ? 0 : null
        }
    };

    const iconLeft = isLoading && !rightIcon
        ? LoadingIcon
        : (isSelected && !rightIcon)
            ? (selectedIcon || icon)
            : icon;

    const iconRight = isLoading && rightIcon
        ? LoadingIcon
        : (isSelected && !icon)
            ? (selectedIcon || rightIcon)
            : rightIcon;

    const label = isLoading
        ? loadingText || (
            <Box as="span" sx={{visibility: 'hidden'}}>
                {children}
            </Box>
        )
        : children;

    return (
        <CoreButton
            as={as || (href ? 'a' : 'button')}
            ref={ref}
            href={href}
            isDisabled={_isDisabled}
            iconLeft={iconLeft}
            iconRight={iconRight}
            onClick={onClick}
            pill={pill}
            size={size}
            fullWidth={fullWidth}
            showTitle={false}
            label={label}
            showLabel={true}
            sx={{
                ...(variants[isSelected ? 'selected' : variant]),
                ...(sx || {})
            }}
            {...restProps}
        />
    );
});

Button.displayName = 'Button';

Button.propTypes = {
    as: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
    /**
     * `pill` set to `true` will change the button's border radius to 100%
     */
    pill: PropTypes.bool,
    /**
     * The color of the button. Supports all theme colors. Colors get
     * automatically darkened and used with alpha for hover states.
     */
    color: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string)
    ]),
    /**
     * use this to alter the text color of the `solid` button to solve contrast issues.
     */
    solidTextColor: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string)
    ]),
    /**
     * The content of the button.
     * At least one of `icon` or `children` should be passed in
     */
    children: PropTypes.node,
    /**
     * If `true`, the button will be disabled.
     */
    isDisabled: PropTypes.bool,
    /**
     * If `true`, the button will show a loading state.
     */
    isLoading: PropTypes.bool,
    /**
     * If `true`, the button will get selected styles.
     */
    isSelected: PropTypes.bool,
    /**
     * Icon placed after the children.
     */
    rightIcon: PropTypes.elementType,
    /**
     * when using a href attribute the button will be rendered as an `<a>`
     * element
     */
    href: PropTypes.string,
    /**
     * Icon placed before the children.
     */
    icon: PropTypes.elementType,
    /**
     * Text which is displayed while the button displays a loading state
     */
    loadingText: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ]),
    /**
     * click handler. when using an onClick attribute the button will be
     * rendered as a `<button>` element
     */
    onClick: PropTypes.func,
    /**
     * icon for the selected state
     */
    selectedIcon: PropTypes.elementType,
    /**
     * color for the selected state
     */
    selectedColor: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string)
    ]),
    /**
     * The size of the button.
     */
    size: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.oneOf(['xs', 'xs+', 'sm', 'sm+', 'md', 'md+', 'lg', 'lg+'])),
        PropTypes.oneOf(['xs', 'xs+', 'sm', 'sm+', 'md', 'md+', 'lg', 'lg+'])
    ]),
    variant: PropTypes.oneOf(['transparent', 'solid', 'outline', 'ghost', 'link']),
    fullWidth: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.bool),
        PropTypes.bool
    ])};

Button.defaultProps = {
    pill: false,
    variant: 'solid',
    solidTextColor: 'textOnDark',
    selectedColor: 'primary',
    selectedIcon: IconSelected,
    color: 'primary',
    size: 'md',
    fullWidth: false
};

export default Button;
