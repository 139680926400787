import React from 'react'; // eslint-disable-line no-unused-vars

import InputPanel from '../InputPanel';

/**
 * Deprecated: Use <InputPanel/> instead
 */
const RadioPanel = React.forwardRef((props, ref) => {
    return (
        <InputPanel
            {...props}
            ref={ref}
            type="radio"
        />
    );
});

RadioPanel.displayName = 'RadioPanel';

RadioPanel.propTypes = {
    ...InputPanel.propTypes,
    type: () => {} // Not required, disable inherited prop type check
};

export default RadioPanel;
