import React, {useContext} from 'react';

export const PageContext = React.createContext();

export const usePageContext = () => {
    return useContext(PageContext);
};

export const PageContextProvider = ({value = {}, children}) => {
    return (
        <PageContext.Provider value={value}>
            {children}
        </PageContext.Provider>
    );
};

export default PageContext;
