import React, {Children} from 'react';
import flattenChildren from 'react-keyed-flatten-children';
import PropTypes from 'prop-types';
import Box from '../Box';
import {FlexCol} from '../Flex';
import Divider from '../Divider';

export const ActionMenuContext = React.createContext();

/**
 * Action Menus are used to display distinct grouped actions (which don't require a selected state)
 */
const ActionMenu = React.forwardRef(({children, bg, color, ...restProps}, ref) => {
    return (
        <ActionMenuContext.Provider
            value={{
                ActionMenuColor: color
            }}
        >
            <Box
                ref={ref}
                {...restProps}
                sx={{
                    bg: bg,
                    borderRadius: 'lg',
                    'li:first-of-type .action-menu-content > *': {borderTopLeftRadius: 'lg', borderTopRightRadius: 'lg'},
                    'li:last-of-type .action-menu-content > *': {borderBottomLeftRadius: 'lg', borderBottomRightRadius: 'lg'}
                }}
            >
                <FlexCol as="ul" space="none">
                    {Children.map(flattenChildren(children), (child, i) => (
                        <Box className="action-menu-content" key={i}>
                            {i !== 0 ? (
                                <Box pl={12}>
                                    <Divider/>
                                </Box>
                            ) : null}
                            {child}
                        </Box>
                    ))}
                </FlexCol>
            </Box>
        </ActionMenuContext.Provider>
    );
});

ActionMenu.displayName = 'ActionMenu';

ActionMenu.propTypes = {
    /**
     * icon color
     */
    color: PropTypes.string,
    /**
     * background color
     */
    bg: PropTypes.string
};

ActionMenu.defaultProps = {
    bg: 'muted'
};

export default ActionMenu;
