import React from 'react';
import PropTypes from 'prop-types';
import Text from '../Text';

const Label = React.forwardRef(({children, bold, ...restProps}, ref) => {
    return (
        <Text
            ref={ref}
            as="label"
            bold={bold}
            display="flex"
            width="100%"
            cursor="pointer"
            {...restProps}
        >
            {children}
        </Text>
    );
});

Label.displayName = 'Label';

Label.propTypes = {
    bold: PropTypes.bool
};

Label.defaultProps = {
    bold: false
};

export default Label;
