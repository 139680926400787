import React, {Fragment, useEffect, useRef, useState} from 'react';

// Utils
import PropTypes from 'prop-types';
import {useColorMode} from 'theme-ui';
import {getPropsByHref} from '../../utils';
import {useThirdPartyConsent} from '../../../hooks';

// Components
import {AspectRatio, Box, Button, FlexCol, Text, TextLink} from '../../../ui/components';

const MdxTwitterEmbed = ({aspectRatio, id}) => {
    const [consent, handleConsent] = useThirdPartyConsent('twitter');
    const [colorMode] = useColorMode();

    // Control embed size
    const ref = useRef();
    const initialEmbedWidth = Math.min(ref.current?.scrollWidth || 550, 550);
    const [embedSize, setEmbedSize] = useState({});

    useEffect(() => {
        const messageHandler = (event) => {
            const isFromTwitter = event.origin === 'https://platform.twitter.com';

            if (isFromTwitter) {
                const isForCurrentEmbed = event.data?.['twttr.embed']?.params?.[0]?.data?.tweet_id === id;

                if (isForCurrentEmbed) {
                    const width = event.data['twttr.embed'].params[0].width;
                    const height = event.data['twttr.embed'].params[0].height;

                    if (width & height) {
                        setEmbedSize({
                            ratio: width / height,
                            width,
                            height
                        });
                    }
                };
            }
        };

        window.addEventListener('message', messageHandler, false);

        return () => {
            window.removeEventListener('message', messageHandler);
        };
    }, [id]);

    // Generate link source link from tweet id
    const externalHref = `https://twitter.com/user/status/${id}`;
    // Generate embed src with query params
    const embedQuery = [
        'dnt=true',
        'embedId=twitter-widget-0',
        'frame=false',
        'hideCard=false',
        'hideThread=true',
        `id=${id}`,
        `lang=de`,
        `theme=${colorMode === 'dark' ? 'dark' : 'light'}`,
        `width=${initialEmbedWidth}px`
    ].join('&');
    const embedSrc = `https://platform.twitter.com/embed/Tweet.html?${embedQuery}`;

    return (
        <FlexCol
            sx={{
                alignSelf: 'center',
                width: '100%',
                maxWidth: `${550 + 64}px`
            }}
        >
            <FlexCol
                alignX="stretch"
                alignY="stretch"
                p={['sm', 'sm', 'md']}
                bg="card"
                borderRadius="lg"
            >
                <AspectRatio
                    ref={ref}
                    ratio={embedSize.ratio || aspectRatio}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: ['md', 'md', 'lg']
                    }}
                >
                    {consent ? (
                        <Box
                            as="iframe"
                            loading="lazy"
                            src={embedSrc}
                            frameBorder="0"
                            marginWidth="0"
                            marginHeight="0"
                            scrolling="no"
                            sx={{
                                display: 'flex',
                                width: `${embedSize.width || initialEmbedWidth}px`,
                                height: `${embedSize.height || initialEmbedWidth}px`
                            }}
                        />
                    ) : (
                        <Fragment>
                            <Box
                                as="svg"
                                sx={{
                                    width: ['60px', '80', '100px'],
                                    maxWidth: '100%',
                                    color: 'textOnCard'
                                }}
                                viewBox="0 0 30 30"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M28,6.937c-0.957,0.425-1.985,0.711-3.064,0.84c1.102-0.66,1.947-1.705,2.345-2.951c-1.03,0.611-2.172,1.055-3.388,1.295 c-0.973-1.037-2.359-1.685-3.893-1.685c-2.946,0-5.334,2.389-5.334,5.334c0,0.418,0.048,0.826,0.138,1.215 c-4.433-0.222-8.363-2.346-10.995-5.574C3.351,6.199,3.088,7.115,3.088,8.094c0,1.85,0.941,3.483,2.372,4.439 c-0.874-0.028-1.697-0.268-2.416-0.667c0,0.023,0,0.044,0,0.067c0,2.585,1.838,4.741,4.279,5.23 c-0.447,0.122-0.919,0.187-1.406,0.187c-0.343,0-0.678-0.034-1.003-0.095c0.679,2.119,2.649,3.662,4.983,3.705 c-1.825,1.431-4.125,2.284-6.625,2.284c-0.43,0-0.855-0.025-1.273-0.075c2.361,1.513,5.164,2.396,8.177,2.396 c9.812,0,15.176-8.128,15.176-15.177c0-0.231-0.005-0.461-0.015-0.69C26.38,8.945,27.285,8.006,28,6.937z" fill="currentColor"/>
                            </Box>

                            <Text size={[2, 3, 4]} align="center" color="textOnCard">
                                {'Externen Inhalt von '}
                                <TextLink
                                    href={externalHref}
                                    {...getPropsByHref(externalHref)}
                                    color="twitterButtonOnCard"
                                    decoration="inverted"
                                >
                                    Twitter
                                </TextLink>
                                {' anzeigen?'}
                            </Text>
                            <Button
                                variant="solid"
                                color="twitterButtonOnCard"
                                solidTextColor="twitterButtonTextOnCard"
                                onClick={handleConsent}
                            >
                                Anzeigen
                            </Button>
                        </Fragment>
                    )}
                </AspectRatio>
            </FlexCol>
        </FlexCol>
    );
};

MdxTwitterEmbed.propTypes = {
    id: PropTypes.string.isRequired
};

MdxTwitterEmbed.defaultProps = {
    aspectRatio: 4 / 3
};

export default MdxTwitterEmbed;
