import React from 'react'; // eslint-disable-line no-unused-vars
import Box from '../Box';
import Divider from '../Divider';
import Text from '../Text';
import PropTypes from 'prop-types';

/**
 * Separator is used to help separating sections
 */
const Separator = React.forwardRef(({
    bg,
    color,
    children,
    ...restProps
}, ref) => {
    return (
        <Box
            ref={ref}
            display="flex"
            alignX="center"
            alignY="center"
            position="relative"
            {...restProps}
        >
            <Box
                bg={bg}
                px={3}
                sx={{
                    position: 'relative',
                    zIndex: 10
                }}
            >
                <Text bold={true} color={color}>
                    {children}
                </Text>
            </Box>
            <Divider position="absolute" left={0} right={0} top="50%" sx={{transform: 'translateY(-50%)'}}/>
        </Box>
    );
});

Separator.displayName = 'Separator';

Separator.propTypes = {
    /**
     * background color of the separator
     */
    bg: PropTypes.string,
    /**
     * text color
     */
    color: PropTypes.string
};

Separator.defaultProps = {
    bg: 'white',
    color: 'whisper'
};

export default Separator;
