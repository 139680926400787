import React, {Children, useMemo} from 'react';

// Utils
import PropTypes from 'prop-types';
import flattenChildren from 'react-keyed-flatten-children';
import {dependencyKey} from '../../utils';

// Components
import Box from '../Box';
import {FlexCol} from '../Flex';

// Helpers
const resolveItems = (items) => {
    // Ensure array
    items = Array.isArray(items)
        ? items
        : [items];

    return items.map((item, i) => {
        if (item === null || item === undefined) {
            return items[i - 1];
        }

        return item;
    });
};

const getItemsValueByIndex = (items, index) => {
    return items[Math.min(items.length - 1, index)];
};

const useGridSx = (itemsCount, {columns, alignX, reverse, rowReverse}) => {
    return useMemo(() => {
        // Resolve items arrays
        columns = resolveItems(columns);
        alignX = resolveItems(alignX);
        reverse = resolveItems(reverse);
        rowReverse = resolveItems(rowReverse);

        const gridTemplateAreas = [];

        const maxBreakpoints = Math.max(...([columns, alignX, reverse, rowReverse].map(array => array.length)));

        for (let i = 0; i < maxBreakpoints; i++) {
            // Get values for current breakpoint
            const breakpointColumns = getItemsValueByIndex(columns, i);
            const breakpointAlignX = getItemsValueByIndex(alignX, i);
            const breakpointReverse = getItemsValueByIndex(reverse, i);
            const breakpointRowReverse = getItemsValueByIndex(rowReverse, i);

            // Get the gridArea for each item
            // Add each twice as this enables it to center items on the last row
            let itemsGridArea = [];
            for (let i = 0; i < itemsCount; i++) {
                itemsGridArea.push(`grid-item-${i}`);
                itemsGridArea.push(`grid-item-${i}`);
            }

            // Reverse all items
            if (breakpointReverse) {
                itemsGridArea = itemsGridArea.reverse();
            }

            // Slice into rows
            let rows = [];
            while (itemsGridArea.length) {
                rows.push(itemsGridArea.splice(0, breakpointColumns * 2));
            }

            // Reverse each row
            if (breakpointRowReverse) {
                rows = rows.map(row => row.reverse());
            }

            // Fill up last row
            if (rows.length >= 1) {
                const lastRow = rows[rows.length - 1];
                while (lastRow.length < (breakpointColumns * 2)) {
                    if (breakpointAlignX === 'left') {
                        lastRow.push('grid-item-r');
                        lastRow.push('grid-item-r');
                    } else if (breakpointAlignX === 'center') {
                        lastRow.unshift('grid-item-l');
                        lastRow.push('grid-item-r');
                    } else if (breakpointAlignX === 'right') {
                        lastRow.unshift('grid-item-l');
                        lastRow.unshift('grid-item-l');
                    }
                }
            }

            // Add gridTemplateAreas for breakpoint
            gridTemplateAreas.push(
                rows.map(row => `"${row.join(' ')}"`)
                    .join(' ')
            );
        }

        return {
            gridTemplateAreas,
            gridAutoFlow: 'column',
            gridAutoColumns: 'minmax(0, 1fr)'
        };
    }, [dependencyKey({itemsCount, columns, alignX, reverse, rowReverse})]);
};

const Grid = React.forwardRef(({
    children,
    columns,
    alignX,
    alignY,
    minHeight,
    reverse,
    rowReverse,
    space,
    wrapChildren,
    ...restProps
}, ref) => {
    // Ensure flattened array of children
    children = flattenChildren(children);
    children = Children.toArray(children);

    const gridSx = useGridSx(children.length, {
        columns,
        alignX,
        reverse,
        rowReverse
    });

    // Wrap children of required
    if (wrapChildren) {
        children = children.map((child, i) => (
            <FlexCol
                key={i}
                gridArea={`grid-item-${i}`}
                alignX="stretch"
                alignY="stretch"
            >
                {child}
            </FlexCol>
        ));
    }

    // Validate grid-area of children
    children.forEach((child, i) => {
        const gridArea = child.props.sx?.gridArea || child.props.gridArea;

        if (!gridArea) {
            throw new Error(`Missing gridArea for child "${i}" of <Grid/>.`);
        } else if (gridArea !== `grid-item-${i}`) {
            throw new Error(`Invalid gridArea "${gridArea}" for child "${i}" of <Grid/>, expected "grid-item-${i}".`);
        }
    });

    return (
        <Box
            ref={ref}
            {...restProps}
            display="grid"
            alignY={alignY}
            minHeight={minHeight}
            sx={{
                ...gridSx,
                gridGap: space
            }}
        >
            {children}
        </Box>
    );
});

Grid.displayName = 'Grid';

Grid.propTypes = {
    alignX: PropTypes.oneOfType([
        PropTypes.oneOf(['left', 'center', 'right']),
        PropTypes.arrayOf(PropTypes.oneOf(['left', 'center', 'right']))
    ]),
    alignY: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string)
    ]),
    columns: PropTypes.oneOfType([
        PropTypes.oneOf([1, 2, 3, 4, 5, 6]),
        PropTypes.arrayOf(PropTypes.oneOf([1, 2, 3, 4, 5, 6]))]
    ).isRequired,
    minHeight: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string)
    ]),
    reverse: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.arrayOf(PropTypes.bool)
    ]),
    rowReverse: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.arrayOf(PropTypes.bool)
    ]),
    space: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.arrayOf(
            PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number
            ])
        )
    ]),
    wrapChildren: PropTypes.bool.isRequired
};

Grid.defaultProps = {
    space: ['sm', 'md'],
    alignX: 'left',
    reverse: false,
    rowReverse: false,
    wrapChildren: false
};

export default Grid;
