/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react'; // eslint-disable-line no-unused-vars
import {jsx} from 'theme-ui';
import PropTypes from 'prop-types';

const alignFromProp = (prop) =>
    ({
        top: 'flex-start',
        left: 'flex-start',
        center: 'center',
        right: 'flex-end',
        bottom: 'flex-end'
    }[prop] || prop);

const flexFromProp = (prop) =>
    ({
        auto: '1 1 auto',
        initial: '0 1 auto',
        1: '1 1 0%'
    }[prop] || prop);

const orderFromProp = (prop) =>
    ({
        first: -9999,
        last: 9999,
        none: 0
    }[prop] || prop);

/**
 * Box is a low-level layout primitive and is used as the **building block for
 * all our components**. It exposes common layout-related css attributes as
 * props, e.g flex, border-radius, etc.
 */
const Box = React.forwardRef(({
    as: Element,
    alignItems,
    alignX,
    alignY,
    appearance,
    bg,
    border,
    borderBottom,
    borderColor,
    borderLeft,
    borderRadius,
    borderRight,
    borderStyle,
    borderTop,
    borderX,
    borderY,
    bottom,
    color,
    cursor,
    boxShadow,
    children,
    display,
    flex,
    flexDirection,
    flexGrow,
    flexWrap,
    gridArea,
    height,
    justifyContent,
    left,
    m,
    maxHeight,
    maxWidth,
    mb,
    minHeight,
    minWidth,
    ml,
    mr,
    mt,
    mx,
    my,
    order,
    overflow,
    p,
    pb,
    pl,
    pointerEvents,
    position,
    pr,
    pt,
    px,
    py,
    right,
    sx,
    textAlign,
    top,
    width,
    zIndex,
    ...restProps
}, ref) => {
    const alignToFlexAlign = (align) =>
        Array.isArray(align) ? align.map(alignFromProp) : alignFromProp(align);

    const resolveFlex = (flex) =>
        Array.isArray(flex) ? flex.map(flexFromProp) : flexFromProp(flex);

    const resolveFlexOrder = (order) =>
        Array.isArray(order) ? order.map(orderFromProp) : orderFromProp(order);

    const resolvedAlignY =
    alignItems ||
    alignToFlexAlign(
        flexDirection &&
        (flexDirection === 'column' || flexDirection === 'column-reverse')
            ? alignX
            : alignY
    );
    const resolvedAlignX =
    justifyContent ||
    alignToFlexAlign(
        (flexDirection === 'column' || flexDirection === 'column-reverse')
            ? alignY
            : alignX
    );

    const resolvedBorderTop = borderTop || borderY || border;
    const resolvedBorderBottom = borderBottom || borderY || border;
    const resolvedBorderLeft = borderLeft || borderX || border;
    const resolvedBorderRight = borderRight || borderX || border;

    const resolvedPaddingTop = pt || py || p;
    const resolvedPaddingBottom = pb || py || p;
    const resolvedPaddingLeft = pl || px || p;
    const resolvedPaddingRight = pr || px || p;

    const resolvedMarginTop = mt || my || m;
    const resolvedMarginBottom = mb || my || m;
    const resolvedMarginLeft = ml || mx || m;
    const resolvedMarginRight = mr || mx || m;

    return (
        <Element
            {...restProps}
            ref={ref}
            sx={{
                alignItems: resolvedAlignY || undefined,
                appearance,
                bg,
                borderBottomWidth: resolvedBorderBottom || undefined,
                borderColor,
                borderLeftWidth: resolvedBorderLeft || undefined,
                borderRadius,
                borderRightWidth: resolvedBorderRight || undefined,
                borderStyle:
                    resolvedBorderTop ||
                    resolvedBorderBottom ||
                    resolvedBorderLeft ||
                    resolvedBorderRight
                        ? borderStyle
                        : undefined,
                borderTopWidth: resolvedBorderTop || undefined,
                bottom,
                boxShadow,
                color,
                cursor,
                display,
                flex: resolveFlex(flex),
                flexDirection,
                flexGrow,
                flexWrap,
                gridArea,
                height,
                justifyContent: resolvedAlignX || undefined,
                left,
                marginBottom: resolvedMarginBottom || undefined,
                marginLeft: resolvedMarginLeft || undefined,
                marginRight: resolvedMarginRight || undefined,
                marginTop: resolvedMarginTop || undefined,
                maxHeight,
                maxWidth,
                minHeight,
                minWidth,
                order: resolveFlexOrder(order),
                overflow,
                paddingBottom: resolvedPaddingBottom || undefined,
                paddingLeft: resolvedPaddingLeft || undefined,
                paddingRight: resolvedPaddingRight || undefined,
                paddingTop: resolvedPaddingTop || undefined,
                pointerEvents,
                position,
                right,
                textAlign,
                top,
                width,
                zIndex,
                ...sx
            }}
        >
            {children}
        </Element>
    );
});

Box.displayName = 'forwardRef(Box)';

Box.displayName = 'Box';

Box.propTypes = {
    alignItems: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
    alignX: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
    alignY: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
    appearance: PropTypes.string,
    as: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
    bg: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.func]),
    border: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
    borderBottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
    borderColor: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    borderLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
    borderRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    borderRight: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
    borderStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    borderTop: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
    borderX: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
    borderY: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
    bottom: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array]),
    boxShadow: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    children: PropTypes.node,
    color: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    cursor: PropTypes.string,
    display: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    flex: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
    flexDirection: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    flexGrow: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    flexWrap: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
    justifyContent: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    left: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
    m: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
    maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
    maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
    mb: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
    minHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
    minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
    ml: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
    mr: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
    mt: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
    mx: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
    my: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
    overflow: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
    overflow: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    p: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
    pb: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
    pl: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
    pointerEvents: PropTypes.string,
    position: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    pr: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
    pt: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
    px: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
    py: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
    sx: PropTypes.object,
    right: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
    textAlign: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    top: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
    zIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array])
};

Box.defaultProps = {
    as: 'div',
    borderStyle: 'solid'
};

export default Box;
