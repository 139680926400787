import React, {useState} from 'react';
import {useThemeUI} from 'theme-ui';
import {alpha} from '@theme-ui/color';
import PropTypes from 'prop-types';

import Box from '../Box';
import Text from '../Text';
import IconButton from '../IconButton';

import {IconClose, IconInfo, IconWarning, IconError, IconSuccess} from '../../icons';;

/**
 * Used as visual user Feedback, e.g. when validating forms or sending error
 * messages on Highlight / Tour creation. Depending on the type of message you
 * want to convey you should choose an appropriate variation which changes
 * the colors and icons. Alerts can be set up to be dismissable by the user.
 */
const Alert = React.forwardRef(({variant, dismissable, children, ...restProps}, ref) => {
    const context = useThemeUI();
    const {theme} = context;

    const [state, setState] = useState({
        isDismissed: false
    });

    const toggleDismiss= () => {
        setState({
            ...state,
            isDismissed: !state.isDismissed
        });
    };

    const alertColor = theme.rawColors[variant];

    const icons = {
        'error': IconError,
        'info': IconInfo,
        'warning': IconWarning,
        'success': IconSuccess
    };

    const AlertIcon = icons[variant];

    return state.isDismissed ? null : (
        <Box
            ref={ref}
            {...restProps}
            sx={{
                borderLeftWidth: 4,
                borderStyle: 'solid',
                display: 'flex',
                alignItems: 'start',
                borderColor: alertColor,
                p: 'xs',
                bg: alpha(alertColor, 0.1),
                ...(restProps.sx || {})
            }}
        >
            <Box
                sx={{
                    flexShrink: 'none',
                    color: alertColor
                }}
            >
                <AlertIcon/>
            </Box>
            <Box sx={{
                flex: 'auto',
                display: 'flex',
                mx: 2,
                alignY: 'center'
            }}
            >
                {typeof children === 'string' ? (
                    <Text>
                        {children}
                    </Text>
                ) : children}
            </Box>
            {dismissable && (
                <Box sx={{
                    flexShrink: 'none'
                }}
                >
                    <IconButton icon={IconClose} color={alertColor} onClick={toggleDismiss}/>
                </Box>
            )}
        </Box>
    );
});

Alert.displayName = 'Alert';

Alert.propTypes = {
    /** Types of banners:
     *
     *  - `error` is shown when something goes wrong.
     *  - `success` is shown when something goes right.
     *  - `warning` is shown when extra care should be taken.
     *  - `info` is shown when neutral information is required.
     */
    variant: PropTypes.oneOf(['error', 'info', 'warning', 'success']),
    dismissable: PropTypes.bool,
    /** The content of the button */
    children: PropTypes.node
};

Alert.defaultProps = {
    variant: 'error',
    dismissable: false
};

export default Alert;
