import React, {Children} from 'react';
import flattenChildren from 'react-keyed-flatten-children';
import PropTypes from 'prop-types';
import Box from '../Box';

export const TabListContext = React.createContext();

/**
 * TabList controls the layouting for tab items and provides overflow behaviour
 * while keeping safe areas for focus rings. Any additional props will be
 * forwarded to the rendered <ul />.
 */
const TabList = ({bg, children, variant, ...restProps}) => {
    return (
        <TabListContext.Provider
            value={{
                TabListBg: bg
            }}
        >
            <Box
                sx={{
                    p: 1,
                    mx: -1,
                    overflowX: 'auto'
                }}
            >
                <Box
                    as="ul"
                    sx={{
                        display: 'grid',
                        position: 'relative',
                        gridAutoFlow: 'column',
                        gridAutoColumns: '1fr',
                        gap: 1,
                        mt: 0,
                        pl: 0,
                        listStyleType: 'none',

                        ':before': variant !== 'underline' ? {
                            content: '""',
                            bg: 'border',
                            display: 'block',
                            height: '1px',
                            width: '100%',
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            right: 0
                        } : undefined
                    }}
                    {...restProps}
                >
                    {Children.map(flattenChildren(children), (child, i) => (
                        <Box
                            as="li"
                            key={i}
                            sx={{
                                position: 'relative'
                            }}
                        >
                            {React.cloneElement(child, {variant})}
                        </Box>
                    ))}
                </Box>
            </Box>
        </TabListContext.Provider>
    );
};

TabList.displayName = 'TabList';

TabList.propTypes = {
    /**
   * background color and bottom border of active elements for
   * all tabs inside the `<TabList>`. Set this to the same value as the
   * background on which the tabs are placed.
   */
    bg: PropTypes.string
};

TabList.defaultProps = {
    bg: 'card'
};

export default TabList;
