import React, {useState, useRef, useEffect} from 'react'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';

import Card from '../Card';
import Box from '../Box';
import {useFocusOutside} from '../../hooks';

/**
 * Returns positioning properties to open a popup towards the center of the screen
 * @param {HTMLElement} element The element to check for alignment value
 */
const getAlignmentProps = (element) => {
    if (!element) {
        return 'left';
    }

    const {x} = element.getBoundingClientRect();
    const viewX = window.innerWidth;
    const leftOfCenter = x <= viewX / 2;

    return {
        left: leftOfCenter ? 0 : 'auto',
        right: leftOfCenter ? 'auto' : 0
    };
};
/**
 * DropdownMenu is used to trigger a popout menu when clicked.
 */
const DropdownMenu = React.forwardRef((props, ref) => {
    const {trigger, children, elevation, zIndex, alignX, alignY, sx, menuProps = {}} = props;
    const watchRef = useRef(ref);
    /** The calculated alignment should be the default  */
    const [calculatedProps, setCalculatedProps] = useState({});

    // Track open state
    const [isOpen, setIsOpen] = useState(false);
    const toggleMenu = (event) => {
        event?.preventDefault();
        event?.stopPropagation();
        const calculatedAlignment = getAlignmentProps(watchRef.current);
        if (alignX === 'auto') {
            // Update the calculated alignment based on DOM element position
            setCalculatedProps(calculatedAlignment);
        }
        setIsOpen(!isOpen);
    };
    const closeMenu = () => setIsOpen(false);

    // Handle events to close
    useFocusOutside(watchRef, closeMenu, [isOpen]);

    const resolvedTop = Array.isArray(alignY)
        ? alignY.map(alignY => alignY === 'top' ? 'auto' : '100%')
        : alignY === 'top' ? 'auto' : '100%';
    const resolvedRight = Array.isArray(alignX)
        ? alignX.map(alignX => alignX === 'left' ? 'auto' : 0)
        : alignX === 'left' ? 'auto' : 0;
    const resolvedBottom = Array.isArray(alignY)
        ? alignY.map(alignY => alignY === 'top' ? '100%' : 'auto')
        : alignY === 'top' ? '100%' : 'auto';
    const resolvedLeft = Array.isArray(alignX)
        ? alignX.map(alignX => alignX === 'left' ? 0 : 'auto')
        : alignX === 'left' ? 0 : 'auto';
    const resolvedMt = Array.isArray(alignY)
        ? alignY.map(alignY => alignY === 'top' ? null : 'xs')
        : alignY === 'top' ? null : 'xs';
    const resolvedMb = Array.isArray(alignY)
        ? alignY.map(alignY => alignY === 'top' ? 'xs' : null)
        : alignY === 'top' ? 'xs' : null;

    return (
        <Box
            sx={{display: 'inline-block', position: 'relative', ...sx}}
            ref={watchRef}
        >
            {trigger({isOpen, onToggle: toggleMenu})}
            {isOpen && (
                <Box
                    position="absolute"
                    top={resolvedTop}
                    right={resolvedRight}
                    bottom={resolvedBottom}
                    left={resolvedLeft}
                    mt={resolvedMt}
                    mb={resolvedMb}
                    zIndex={zIndex}
                    {...calculatedProps}
                >
                    <Card
                        minWidth={48}
                        elevation={elevation}
                        {...menuProps}
                        onClick={closeMenu}
                    >
                        {children}
                    </Card>
                </Box>
            )}
        </Box>
    );
});

DropdownMenu.displayName = 'DropdownMenu';

DropdownMenu.propTypes = {
    /** shadow size */
    elevation: PropTypes.oneOf([1, 2, 3]),
    /** menu align on x-axis */
    alignX: PropTypes.oneOf(['left', 'right', 'auto']),
    /** menu align on y-axix (above/below the trigger) */
    alignY: PropTypes.oneOf(['top', 'bottom']),
    /** z-index of the menu */
    zIndex: PropTypes.oneOf(['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'])
};

DropdownMenu.defaultProps = {
    elevation: 2,
    zIndex: '1',
    alignX: 'auto',
    alignY: 'bottom'
};

export default DropdownMenu;
