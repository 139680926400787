import React from 'react';

// Components
import {Preflight, ThemeProvider} from './src/ui/components';
import {MatomoTrackingScript} from './src/mdx/components';

export const wrapRootElement = ({element, ...restProps}) => {
    return (
        <ThemeProvider>
            <Preflight>
                {element}
            </Preflight>
            <MatomoTrackingScript/>
        </ThemeProvider>
    );
};
