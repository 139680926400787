import React from 'react'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';

import Box from '../Box';
import Label from '../Label';

import {IconCheckboxChecked, IconCheckboxUnchecked} from '../../icons';

const CheckboxIcon = ({color, ...restProps}) => {
    return (
        <React.Fragment>
            <IconCheckboxChecked
                {...restProps}
                sx={{
                    color: 'border',
                    display: 'none',
                    'input:checked ~ &': {
                        color: color,
                        display: 'block'
                    }
                }}
            />
            <IconCheckboxUnchecked
                {...restProps}
                sx={{
                    display: 'block',
                    color: 'border',
                    'input:checked ~ &': {
                        display: 'none'
                    }
                }}
            />
        </React.Fragment>
    );
};

const Checkbox = React.forwardRef(({as, children, maxWidth, space, size, color, ...restProps}, ref) => {
    return (
        <Box
            as={as || Label}
            alignY="center"
            sx={{
                display: 'flex',
                position: 'relative',
                maxWidth: maxWidth
            }}
        >
            <Box
                ref={ref}
                as="input"
                type="checkbox"
                {...restProps}
                sx={{
                    position: 'absolute',
                    opacity: 0,
                    margin: 0,
                    width: '100%',
                    height: '100%',
                    cursor: 'pointer',
                    overflow: 'hidden'
                }}
            />
            <CheckboxIcon
                aria-hidden="true"
                size={size}
                sx={{
                    flexShrink: 0,
                    borderRadius: 'md',
                    cursor: 'pointer',
                    mr: children ? space : null,
                    color: 'border',
                    'input:checked ~ &': {
                        color
                    },
                    'input:focus ~ &': {
                        outline: 0,
                        bg: 'rgba(66, 153, 225, 0.1)',
                        borderColor: '#63b3ed !important',
                        boxShadow: '0 0 0 3px rgba(66, 153, 225, 0.5)'
                    },
                    'input:disabled ~ &': {
                        color: 'border'
                    }
                }}
            />
            {children}
        </Box>
    );
});

Checkbox.displayName = 'Checkbox';

Checkbox.propTypes = {
    /** size of the checkbox icon */
    size: PropTypes.number,
    /** by default checkboxes are set to wrap only around the content. you can
    * change this if you e.g. need to stretch the label to the parent container
    * for a better click/focus area */
    maxWidth: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
        PropTypes.number
    ]),
    /** as long as children are present we are applying a `margin-right` to the checkbox */
    space: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
        PropTypes.number
    ])
};

Checkbox.defaultProps = {
    size: 6,
    maxWidth: 'max-content',
    color: 'primary',
    space: 'xs'
};

export default Checkbox;
