import React from 'react'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';

import Box from '../Box';
import Label from '../Label';
import {FlexCol} from '../Flex';

const Input = React.forwardRef((props, ref) => {
    const {id, label, readOnly, overlay, invalid, bold, width, bg, ...restProps} = props;

    const inputField = (
        <Box position="relative" sx={{width: width || '100%'}}>
            <Box as="input" id={id} ref={ref} readOnly={readOnly} {...restProps} sx={{
                appearance: 'none',
                display: 'block',
                width: width || '100%',
                fontSize: 'inherit',
                fontFamily: 'inherit',
                lineHeight: 'inherit',
                minHeight: 10,
                border: '1px solid',
                borderColor: invalid ? 'error' : 'border',
                borderRadius: 'md',
                color: readOnly ? 'whisper' : 'inherit',
                bg: readOnly ? 'rgba(0,0,0,0.05)' : bg || 'transparent',
                cursor: readOnly ? 'text' : null,
                px: 2,
                pr: overlay ? 10 : 2,
                '::placeholder': {
                    color: 'whisper',
                    opacity: 0.8
                }
            }}
            />
            {overlay && (
                <Box
                    position="absolute"
                    right={0}
                    top={0}
                    bottom={0}
                    display="flex"
                    alignY="center"
                >
                    {overlay}
                </Box>
            )}
        </Box>
    );

    // Add label for input field
    if (label) {
        return (
            <FlexCol space="sm">
                <Label htmlFor={id} bold={bold}>
                    {label}
                </Label>
                {inputField}
            </FlexCol>
        );
    }

    // "Plain" input field
    return inputField;
});

Input.displayName = 'Input';

Input.propTypes = {
    /**
     * unique id for the input. when a label is provided this will automatically
     * be used as the `for` attribute
     */
    id: PropTypes.string.isRequired,
    /**
     * Label for the input. Make sure to skip this only when you are providing
     * an alternative label (and have a good reason to break our design
     * conventions around labels on top of inputs)
     */
    label: PropTypes.node,
    /**
     * applies disabled styles and `readOnly` (watch the casing!) to the input
     */
    readOnly: PropTypes.bool,
    /**
     * when an overlay provided it'll be positioned to the right of the input
     * and additional padding applied. use this for actions like clear or show
     * password
     */
    overlay: PropTypes.node,
    /**
     * this controls wether the label should be bold or not
     */
    bold: PropTypes.bool,
    /**
     * invalid inputs are highlighted with a red border
     */
    invalid: PropTypes.bool
};

Input.defaultProps = {
    bold: true
};

export default Input;
