import React from 'react'; // eslint-disable-line no-unused-vars
import Box from '../Box';
import PropTypes from 'prop-types';

/**
 * Badges are used to highlight text, e.g. display difficulty or rebates and
 * will grow based on the content. The spacing around the text is based on the
 * font-size. Round Badges get different vertical `padding` and `min-width`
 * based on the font size to allow "square" badges, e.g. for notifications
 */
const Badge = React.forwardRef(({
    color,
    size,
    round,
    children,
    bg,
    uppercase,
    sx,
    ...restProps
}, ref) => {
    const borderRadius = Array.isArray(round)
        ? round.map(round ? 'full': 'sm')
        : round ? 'full': 'sm';

    const minWidth = Array.isArray(round)
        ? round.map(round ? '1.5em' : '2em')
        : round ? '1.5em' : '2em';

    const px = Array.isArray(round)
        ? round.map(round ? '0.75em' : '0.5em')
        : round ? '0.75em' : '0.5em';

    const textTransform = Array.isArray(uppercase)
        ? uppercase.map(uppercase ? 'uppercase' : null)
        : uppercase ? 'uppercase' : null;

    return (
        <Box
            ref={ref}
            {...restProps}
            sx={{
                alignItems: 'center',
                bg,
                borderRadius: borderRadius,
                color,
                display: 'inline-flex',
                fontSize: size,
                fontWeight: 'bold',
                justifyContent: 'center',
                minWidth: minWidth,
                px: px,
                py: '0.25em',
                textAlign: 'center',
                textTransform: textTransform,
                ...sx
            }}
        >
            {children}
        </Box>
    );
});

Badge.displayName = 'Badge';

Badge.propTypes = {
    /** Background Color */
    bg: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.string
    ]).isRequired,
    /** Text Color */
    color: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.string
    ]).isRequired,
    /** Size */
    size: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.arrayOf(PropTypes.number)
    ]).isRequired,
    /** transforms casing of the text to uppercase */
    uppercase: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.bool),
        PropTypes.bool
    ]).isRequired,
    /** Round Badges get different vertical `padding` and `min-width` based on the font size to allow "square" badges, e.g. for notifications */
    round: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.bool),
        PropTypes.bool
    ]).isRequired
};

Badge.defaultProps = {
    bg: 'info',
    color: 'white',
    size: 0,
    round: false,
    uppercase: false
};

export default Badge;
