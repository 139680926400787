/** @jsxRuntime classic */
/** @jsx jsx */
/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import { jsx, useThemeUI } from "theme-ui";

const IconHome = ({
  size,
  color,
  sx,
  width,
  height,
  viewBox,
  fill,
  xmln,
  ...props
}) => {
  const { theme } = useThemeUI();
  const iconSize = Array.isArray(size)
    ? size.map((size) => theme.sizes[size] || size)
    : theme.sizes[size] || size;
  const iconColor = Array.isArray(color)
    ? color.map((color) => theme.rawColors[color] || color)
    : theme.rawColors[color] || color;
  const iconStyle = {
    height: iconSize,
    width: iconSize,
    color: iconColor,
    ...sx,
  };
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={iconStyle}
      {...props}
    >
      <path
        d="M11.653 3.336l-8.5 8.212a.5.5 0 00-.153.36v.592a.5.5 0 00.5.5h.648a.5.5 0 00.347-.14l7.158-6.916a.5.5 0 01.694 0l7.158 6.916a.5.5 0 00.347.14h.648a.5.5 0 00.5-.5v-.592a.5.5 0 00-.153-.36l-8.5-8.212a.5.5 0 00-.694 0zM5 3.5a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v2.793a.5.5 0 01-.146.353l-1 1L5 8.5v-5z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.563 21a.434.434 0 00.437-.43v-6.696a.426.426 0 00-.128-.304l-6.563-6.444a.443.443 0 00-.618 0L5.128 13.57a.426.426 0 00-.128.304v6.696c0 .238.196.43.438.43h13.125zm-9.188-6.874a.867.867 0 00-.875.86v2.577c0 .475.392.86.875.86h5.25a.867.867 0 00.875-.86v-2.578a.867.867 0 00-.875-.86h-5.25z"
        fill="currentColor"
      />
    </svg>
  );
};

IconHome.propTypes = {
  size: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    ),
    PropTypes.number,
    PropTypes.string,
  ]),
  color: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
};
IconHome.defaultProps = {
  size: 6,
  color: "currentColor",
};
export default IconHome;
