import React from 'react';

// Images
import logoEvanvosbergPng from '../../../images/logo-evanvosberg.webp';
import logoEvSvg from '../../../images/logo-ev.svg';

// Components
import {Box, FlexCol, FlexRow, Heading, Text} from '../../../ui/components';

const MdxPreHeader = () => {
    return (
        <FlexRow
            alignX={['center', null, null, 'stretch']}
            alignY="center"
            space="lg"
            width="100%"
            maxWidth="5xl"
            minHeight="xxs"
            px={['md', 'lg']}
            py="md"
        >
            <Box
                as="img"
                display={['none', null, 'flex']}
                width="100%"
                height="100%"
                maxWidth={32}
                maxHeight={32}
                src={logoEvanvosbergPng}
                alt="Avatar Evan Vosberg"
            />
            <FlexCol
                alignX="center"
                alignY="center"
                space="sm"
            >
                <Heading as="p" level={[2, 1]} align="center" color="textOnHeader">
                    {'Evan Vosberg'}
                </Heading>
                <Text size={[1, 3]} align="center" color="textOnHeader">
                    {'Engineer / Designer / Blogger / Cyclist'}
                </Text>
            </FlexCol>
            <Box
                as="img"
                display={['none', null, null, 'flex']}
                maxWidth={32}
                maxHeight={32}
                src={logoEvSvg}
                alt="Logo Evan Vosberg"
            />
        </FlexRow>
    );
};

export default MdxPreHeader;

