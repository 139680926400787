import PropTypes from 'prop-types';
import React from 'react';
import {FlexCol} from '../Flex';

export const BulletListContext = React.createContext();

/** Unordered List with Bullets in front. */
const BulletList = React.forwardRef(({children, icon, color, size, variant, space, ...restProps}, ref) => {
    return (
        <BulletListContext.Provider
            value={{
                bulletVariant: variant,
                bulletColor: color,
                bulletSize: size,
                bulletIcon: icon
            }}
        >
            <FlexCol ref={ref} as="ul" space={space} {...restProps}>
                {children}
            </FlexCol>
        </BulletListContext.Provider>
    );
});

BulletList.displayName = 'BulletList';

BulletList.propTypes = {
    /** element placed in front of the text */
    variant: PropTypes.string,
    /** dot color  */
    color: PropTypes.string,
    /** font size */
    size: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
    /** space between list items */
    space: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
        PropTypes.number
    ])
};

BulletList.defaultProps = {
    color: 'text',
    space: 'xxs'
};

export default BulletList;
