import React from 'react'; // eslint-disable-line no-unused-vars
import {Spinner} from 'theme-ui';
import PropTypes from 'prop-types';

const LoadingSpinner = React.forwardRef((props, ref) => {
    return <Spinner {...props} ref={ref}/>;
});

LoadingSpinner.displayName = 'LoadingSpinner';

LoadingSpinner.propTypes = {
    strokeWidth: PropTypes.number,
    color: PropTypes.string,
    size: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.number),
        PropTypes.number
    ]),
    title: PropTypes.string
};

LoadingSpinner.defaultProps = {
    title: 'loading',
    color: 'whisper',
    strokeWidth: 4,
    size: 24
};

export default LoadingSpinner;