// Images
import subFooterBgSvg from '../../images/sub-footer-bg.svg';

const useSubFooterBgSx = (src) => {
    return {
        backgroundSize: 'contain',
        backgroundPosition: 'center bottom',
        backgroundRepeat: 'repeat no-repeat',
        backgroundImage: [
            `url(${subFooterBgSvg})`
        ]
    };
};

export default useSubFooterBgSx;
