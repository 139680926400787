import React, {useState} from 'react';

// Utils
import {useColorMode, useThemeUI} from 'theme-ui';
import {useIsomorphicLayoutEffect} from '../../../hooks';

// Components
import {Box, Card} from '../../../ui/components';

const MdxMatomoOptOutCard = (props) => {
    const {theme} = useThemeUI();
    const {mode} = useColorMode();

    const [src, setSrc] = useState(null);

    useIsomorphicLayoutEffect(() => {

        const backgroundColor = (mode === 'dark' ? theme.rawColors.dark.card : theme.rawColors.card).replace(/^#/, '');
        const fontColor = (mode === 'dark' ? theme.rawColors.dark.text : theme.rawColors.text).replace(/^#/, '');

        const query = [
            'module=CoreAdminHome',
            'action=optOut',
            'language=de',
            `backgroundColor=${backgroundColor}`,
            `fontColor=${fontColor}`,
            `fontSize=${theme.fontSizes[3]}px`,
            `fontFamily=${theme.fonts.body}`
        ].join('&');

        setSrc(`https://matomo.urban.to/index.php?${query}`);
    }, [mode]);

    if (!src) {
        return null;
    }

    return (
        <Card p={['sm', 'md', 'lg']} width="100%" {...props}>
            <Box
                key={mode}
                as="iframe"
                sx={{
                    border: 0,
                    height: ['250px', '165px', '120px'],
                    width: '100%'
                }}
                src={src}
            />
        </Card>
    );
};

export default MdxMatomoOptOutCard;
