import React from 'react';
import Box from '../Box';
import PropTypes from 'prop-types';
import {FlexRow} from '../Flex';
import Text from '../Text';
import {FlexCol} from '../Flex';
import Divider from '../Divider';
import {alpha} from '@theme-ui/color';

/**
 * The <Navbar> component always appears at the top of a view and contains it's
 * title as well as an optional control element, like “Add” to
 * create a new content element for the current view. Examples for the actions
 * include e.g. tour and highlight list on user profiles. Collapses into a one-column layout on smaller screens.
 */
const Navbar = React.forwardRef(({bg, title, titleAlign, right, sticky, stickyPositionTop}, ref) => (
    <Box
        ref={ref}
        sx={{
            bg: alpha(bg, 0.95),
            position: sticky ? 'sticky' : 'static',
            top: stickyPositionTop,
            overflow: 'hidden'
        }}
    >
        <FlexCol space={['sm', 'md']}>
            <FlexRow alignY="center" collapse={[true, false]}>
                <FlexRow>
                    {title && (
                        <Text as="h1" bold={true} size={[5, 5, 6]} align={right ? ['center', 'left'] : (titleAlign || 'center')}>
                            {title}
                        </Text>
                    )}
                </FlexRow>
                {right && (
                    <FlexRow width="content">
                        <Box sx={{textAlign: ['center', 'right']}}>
                            {right}
                        </Box>
                    </FlexRow>
                )}
            </FlexRow>
            <Divider size={4}/>
        </FlexCol>
    </Box>
));

Navbar.displayName = 'Navbar';

Navbar.propTypes = {
    /**
     * content on the right of the title
     */
    right: PropTypes.element,
    /**
     * controls wether the `Navbar` receices `position: sticky`
     */
    sticky: PropTypes.bool,
    /**
     * offset to be used together with sticky
     */
    stickyPositionTop: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /**
     * Title of the navbar – automatically formatted as heading and rendered as `h1` (as it's most likely the first heading on the page)
     */
    title: PropTypes.string,
    /**
     * Alignment of the Title – only used when the right prop is not set (defaults to 'center').
     */
    titleAlign: PropTypes.string,
    /**
     * Header background
     */
    bg: PropTypes.string
};

Navbar.defaultProps = {
    bg: 'card',
    stickyPositionTop: 0
};

export default Navbar;
