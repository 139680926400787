/** @jsxRuntime classic */
/** @jsx jsx */
/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import { jsx, useThemeUI } from "theme-ui";

const IconEmail = ({
  size,
  color,
  sx,
  width,
  height,
  viewBox,
  fill,
  xmln,
  ...props
}) => {
  const { theme } = useThemeUI();
  const iconSize = Array.isArray(size)
    ? size.map((size) => theme.sizes[size] || size)
    : theme.sizes[size] || size;
  const iconColor = Array.isArray(color)
    ? color.map((color) => theme.rawColors[color] || color)
    : theme.rawColors[color] || color;
  const iconStyle = {
    height: iconSize,
    width: iconSize,
    color: iconColor,
    ...sx,
  };
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={iconStyle}
      {...props}
    >
      <path
        d="M2 7a3 3 0 013-3h14a3 3 0 013 3v10a3 3 0 01-3 3H5a3 3 0 01-3-3V7zm10 7l7.331-5.499a.817.817 0 10-.985-1.302L12 12.047 5.704 7.2a.831.831 0 10-1.006 1.323L12 14z"
        fill="currentColor"
      />
    </svg>
  );
};

IconEmail.propTypes = {
  size: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    ),
    PropTypes.number,
    PropTypes.string,
  ]),
  color: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
};
IconEmail.defaultProps = {
  size: 6,
  color: "currentColor",
};
export default IconEmail;
