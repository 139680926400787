import React from 'react';

// Utils
import {createComponent, getPropsByHref} from '../../utils';

// Components
import {MDXContentContextProvider} from '../../contexts';
import {Box, Card, Flex, FlexCol, Text, TextLink} from '../../../ui/components';

const components = {
    p: createComponent('p', Text, {
        size: 2,
        lineHeight: '1.5rem',
        pb: 'sm',
        sx: {
            ':last-child': {
                pb: 'none'
            }
        }
    }),
    a: createComponent('a', TextLink, ({href}) => ({
        href,
        decoration: 'inverted',
        ...getPropsByHref(href)
    })),
    em: createComponent('em', Box, {as: 'em', sx: {fontStyle: 'italic'}}),
    strong: createComponent('strong', Box, {as: 'strong', sx: {fontWeight: 'bold'}})
};

const MdxComment = ({author, href, date, level = 0, children}) => {
    return (
        <FlexCol space="sm" pl={['none', 'md', 'lg', 'xl'][level]}>
            <Flex
                variant={['column', 'row']}
                space="xs"
                alignX="space-between"
                alignY="bottom"
            >
                <Box
                    as={href ? TextLink : Text}
                    href={href}
                    {...getPropsByHref(href)}
                    decoration="none"
                    color="textOnBackground"
                    size={4}
                >
                    {author}
                </Box>
                {date ? (
                    <Text size="1" color="whisper">
                        {new Intl.DateTimeFormat('de-DE', {year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric'}).format(new Date(date))}
                    </Text>
                ) : null}
            </Flex>
            <Card
                mt="sm"
                ml="md"
                p={['sm', 'sm', 'md']}
                sx={{
                    position: 'relative',
                    borderRadius: 'lg',
                    ':after': {
                        content: '""',
                        display: 'block',
                        border: '16px solid transparent',
                        borderBottomColor: 'card',
                        position: 'absolute',
                        zIndex: '10',
                        top: '0',
                        left: 'md',
                        marginTop: '-32px'
                    }
                }}
            >
                <MDXContentContextProvider components={components}>
                    {children}
                </MDXContentContextProvider>
            </Card>
        </FlexCol>
    );
};

export default MdxComment;
