import React, {useContext} from 'react';
import PropTypes from 'prop-types';

import Box from '../Box';
import {FlexRow} from '../Flex';
import {ActionMenuContext} from '../ActionMenu';

const ActionMenuItem = React.forwardRef((props, ref) => {
    const {as, icon: Icon, color, onClick, href, children, selected} = props;

    const {ActionMenuColor} = useContext(ActionMenuContext) || false;

    return (
        <Box
            data-test-id={props['data-test-id']}
            ref={ref}
            as={as || (href ? 'a' : 'button')}
            appearance="none"
            display="block"
            width="100%"
            cursor="pointer"
            textAlign="left"
            href={href}
            onClick={onClick}
            py={3}
            sx={{
                color: 'text',
                ':hover, :focus': {
                    bg: 'rgba(0,0,0,0.05)'
                },
                '.action-menu-icon': {
                    color: ActionMenuColor || color
                },
                ':hover .action-menu-icon, :focus .action-menu-icon': {
                    color: 'text'
                },
                bg: selected ? 'rgba(0,0,0,0.05)' : 'initial',
                fontWeight: selected ? 'bold' : 'initial'
            }}
        >
            <FlexRow alignY="center" space="none">
                <FlexRow width={12}>
                    <Box pl={3}>
                        <Icon className="action-menu-icon"/>
                    </Box>
                </FlexRow>
                <FlexRow>
                    {children}
                </FlexRow>
            </FlexRow>
        </Box>
    );
});

ActionMenuItem.displayName = 'ActionMenuItem';

ActionMenuItem.propTypes = {
    /**
     * `as` defines which HTML element should be used for rendering this
     * component. if this is not set it'll automatically fall back to either
     * `a` or `button` depending on the `href` and `onClick` props
     */
    as: PropTypes.string,
    /**
     * icon color
     */
    color: PropTypes.string,
    /**
     * pass an icon component to display next to the menu item
     */
    icon: PropTypes.elementType.isRequired,
    /**
     * link to another page
     */
    href: PropTypes.string,
    /**
     * action which should be triggered onClick
     */
    onClick: PropTypes.func
};

ActionMenuItem.defaultProps = {
    color: 'whisper'
};

export default ActionMenuItem;
