import React from 'react';

// Utils
import {useGetImageBySrc} from '../../hooks';

// Components
import {GatsbyImage} from 'gatsby-plugin-image';
import {Box} from '../../../ui/components';

const MdxImage = ({src, ...restProps}) => {

    const getImageBySrc = useGetImageBySrc();

    const image = getImageBySrc(src);

    if (!image) {
        return null;
    }

    return (
        <Box as={GatsbyImage}
            loading="lazy"
            image={image}
            alt=""
            {...restProps}
        />
    );
};

export default MdxImage;
