import React, {useContext} from 'react';

export const MDXContentContext = React.createContext({});

export const useMDXContentContext = () => {
    return useContext(MDXContentContext);
};

export const MDXContentContextProvider = ({components = {}, children}) => {
    return (
        <MDXContentContext.Provider value={components}>
            {children}
        </MDXContentContext.Provider>
    );
};

export default MDXContentContext;
