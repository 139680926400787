import React from 'react'; // eslint-disable-line no-unused-vars
import {useThemeUI} from 'theme-ui';

import Box from '../Box';
import Text from '../Text';
import PropTypes from 'prop-types';

/**
 *
 * Simple Placeholder component used for prototyping in playroom.
 */
const Placeholder = React.forwardRef(({
    bg,
    width,
    color,
    height,
    rounded,
    label
}, ref) => {
    const context = useThemeUI();
    const {theme} = context;

    const fillColor = color ? theme.rawColors[color] : 'currentColor';

    return (
        <Box
            ref={ref}
            bg={bg}
            width={width}
            height={height}
            border={2}
            borderColor={fillColor}
            borderRadius={rounded ? 'full' : null}
            display="inline-flex"
            alignX="center"
            alignY="center"
            position="relative"
            overflow="hidden"
        >
            {label ? (
                <Text>
                    {label}
                </Text>
            ) : (
                <Box
                    as="svg"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    sx={{
                        fill: fillColor,
                        position: 'absolute',
                        width: '100%',
                        height: '100%'
                    }}
                >
                    <line
                        x1={0}
                        y1={0}
                        x2="100%"
                        y2="100%"
                        strokeWidth="2px"
                        stroke={fillColor}
                    />
                    <line
                        x1="100%"
                        y1={0}
                        x2={0}
                        y2="100%"
                        strokeWidth="2px"
                        stroke={fillColor}
                    />
                </Box>
            )}
        </Box>
    );
});

Placeholder.displayName = 'Placeholder';

Placeholder.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.number]),
    rounded: PropTypes.bool,
    color: PropTypes.string,
    label: PropTypes.string
};

Placeholder.defaultProps = {
    width: '100%',
    bg: 'muted',
    rounded: false,
    color: 'border',
    height: 24
};

export default Placeholder;
