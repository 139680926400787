import React from 'react';

// Utils
import {alpha} from '@theme-ui/color';
import {usePageContext} from '../../contexts';

// Components
import {Box, Button, DropdownMenu, FlexCol} from '../../../ui/components';
import {IconMenu} from '../../../ui/icons';

const MenuItem = ({color, title, href}) => {
    return (
        <Box
            as="a"
            href={href}
            sx={{
                display: 'flex',
                alignSelf: 'stretch',
                p: 'xs',
                color: color,
                ':hover': {
                    bg: alpha(color, 0.1)
                }
            }}
        >
            {title}
        </Box>
    );
};

const MenuSeparator = ({color}) => {
    return (
        <Box
            sx={{
                display: 'flex',
                alignSelf: 'stretch',
                borderBottomWidth: '1px',
                borderBottomStyle: 'solid',
                borderBottomColor: 'textOnFooter'
            }}
        />
    );
};

const MdxMenu = ({
    color,
    menuColor,
    menuBg,
    showLabel,
    alignY
}) => {
    const {aboutPages} = usePageContext();

    const MenuTrigger = ({isOpen, onToggle}) => (
        <Button
            size="sm+"
            variant="ghost"
            color={color}
            pill={true}
            icon={IconMenu}
            title="Menü"
            showTitle={isOpen ? false : showLabel ? false : true}
            showLabel={showLabel}
            onClick={onToggle}
        >
            {'Menü'}
        </Button>
    );

    return (
        <DropdownMenu
            alignY={alignY}
            zIndex="5"
            menuProps={{
                as: FlexCol,
                space: 'none',
                bg: menuBg,
                alignX: 'stretch'
            }}
            trigger={MenuTrigger}
        >
            <MenuItem color={menuColor} title="Startseite" href="/"/>
            <MenuItem color={menuColor} title="Artikel" href="/page/1/"/>
            <MenuItem color={menuColor} title="Kategorien" href="/tags/"/>
            <MenuSeparator color={menuColor}/>
            {aboutPages.items.map(page => (
                <MenuItem
                    key={`menu_${page.page.key}`}
                    color={menuColor}
                    title={page.meta.title}
                    href={page.page.pathname}
                />
            ))}
        </DropdownMenu>
    );
};

MdxMenu.defaultProps = {
    showLabel: false,
    alignY: 'bottom',
    color: 'textOnCard',
    menuBg: 'card',
    menuColor: 'textOnCard'
};

export default MdxMenu;
