import React from 'react';
import Box from '../Box';
import PropTypes from 'prop-types';
import {shade} from '@theme-ui/color';
import {sizeFromProp} from '../Text';

/**
 * Use TextLink for inline links. Links which are part of body copy should
 * always be underlined.
 */
const TextLink = React.forwardRef(({children, decoration, color, size, href, onClick, sx, ...restProps}, ref) => {
    const linkDecorationIdle = {
        'none': 'none',
        'default': 'underline',
        'inverted': 'none'
    };

    const linkDecorationHover = {
        'none': 'none',
        'default': 'none',
        'inverted': 'underline'
    };

    const resolveFontSize = (size) =>
        Array.isArray(size) ? size.map(sizeFromProp) : sizeFromProp(size);

    return (
        <Box
            ref={ref}
            as={href ? 'a' : 'button'}
            href={href}
            onClick={onClick}
            {...restProps}
            sx={{
                appearance: 'none',
                display: 'inline',
                bg: 'transparent',
                color: color,
                fontSize: resolveFontSize(size),
                textDecoration: linkDecorationIdle[decoration],
                ':hover, :focus': {
                    textDecoration: linkDecorationHover[decoration],
                    color: Array.isArray(color)
                        ? color.map(color => shade(color, 0.15))
                        : shade(color, 0.15)
                },
                ...sx
            }}
        >
            {children}
        </Box>
    );
});

TextLink.displayName = 'TextLink';

TextLink.propTypes = {
    /**
   * Link color
   */
    color: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string)
    ]),
    /**
     * responsive prop. all ui text should be set in 3 distinctive sizes. if you
     * really need to have a custom size pick it from our font-size
     * scale which uses numbers instead of strings
     */
    size: PropTypes.oneOfType([PropTypes.number, PropTypes.array, PropTypes.oneOf(['sm', 'md', 'lg', 'inherit'])]),
    /**
   * Link decoration. `inverted` shows underline on hover.
   */
    decoration: PropTypes.oneOf(['none', 'default', 'inverted']),
    /**
   * Link target
   */
    href: PropTypes.string,
    /**
   * Action
   */
    onClick: PropTypes.func,
    sx: PropTypes.object
};

TextLink.defaultProps = {
    color: 'primary',
    size: 'inherit',
    decoration: 'default'
};

export default TextLink;
