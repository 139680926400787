import React from 'react'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';
import Box from '../Box';
import Heading from '../Heading';
import {FlexCol} from '../Flex';

/**
 * Use this component when you need a large title for pages, views or sections.
 */
const Title = React.forwardRef(({
    align,
    as,
    color,
    decoratorColor,
    level,
    children
}, ref) => {
    return (
        <FlexCol ref={ref} space="md" alignX={align}>
            <Box bg={decoratorColor} width="48px" height="6px"/>
            <Heading as={as} level={level} color={color} align={align}>
                {children}
            </Heading>
        </FlexCol>
    );
});

Title.displayName = 'Title';

Title.propTypes = {
    /* Element used for the `<Heading>` element */
    as: PropTypes.string,
    /* Heading color */
    color: PropTypes.string,
    /* color of the decorative box on top of the component */
    decoratorColor: PropTypes.string,
    /* alignment */
    align: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    /* Level used for the `<Heading>` element */
    level: PropTypes.number
};

Title.defaultProps = {
    align: 'center',
    decoratorColor: 'primary',
    level: 2
};

export default Title;
