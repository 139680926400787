
import React from 'react';

// Utils
import {alpha} from '@theme-ui/color';

// Components
import Image from '../Image';
import {
    AspectRatio,
    Box,
    Card,
    Heading,
    Text,
    TextClamp,
    TextLink
} from '../../../ui/components';

const MdxArticleCard = ({
    article
}) => {
    return (
        <AspectRatio
            ratio={0.7874015748}
            as={Card}
            sx={{
                display: 'flex',
                width: '100%',
                height: '100%',
                flexDirection: 'column'
            }}
        >
            <Heading
                as="h3"
                level={4}
                position="relative"
                p="md"
                flex="0"
                sx={{
                    borderColor: 'border',
                    borderStyle: 'solid',
                    borderBottomWidth: '1px',
                    ':before': {
                        content: '""',
                        display: 'block',
                        border: '12px solid transparent',
                        borderTopColor: 'border',
                        position: 'absolute',
                        zIndex: '10',
                        bottom: '0',
                        left: ['sm', 'md'],
                        marginBottom: '-24px'
                    },
                    ':after': {
                        content: '""',
                        display: 'block',
                        border: '11px solid transparent',
                        borderTopColor: 'card',
                        position: 'absolute',
                        zIndex: '10',
                        bottom: '0',
                        left: ['sm', 'md'],
                        marginLeft: '1px',
                        marginBottom: '-21px'
                    }
                }}
            >
                <TextLink href={article.page.pathname} decoration="none" color="text">
                    {article.meta.title}
                </TextLink>
            </Heading>
            <Box
                position="relative"
                p="md"
                flex="1 1 auto"
                sx={{
                    overflow: 'hidden',
                    minWidth: 0,
                    zIndex: '0'
                }}
            >
                <TextClamp height="100%" maxHeight="100%" overflow="hidden" sx={{zIndex: '1'}}>
                    {article.meta.description}
                </TextClamp>
                {article.meta.keyImage ? (
                    <Box position="absolute" top={0} right={0} bottom={0} left={0} sx={{zIndex: '-1'}}>
                        <Image
                            src={article.meta.keyImage}
                            sx={{
                                height: '100%'
                            }}
                            imgStyle={{
                                objectFit: 'cover'
                            }}
                        />
                        <Box position="absolute" top={0} right={0} bottom={0} left={0}
                            bg={alpha('card', 0.75)}
                        />
                    </Box>
                ) : null}
            </Box>
            <Box
                p="md"
                flex="0"
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    borderColor: 'border',
                    borderStyle: 'solid',
                    borderTopWidth: '1px'
                }}
            >
                <Text
                    color="whisper"
                >
                    {new Intl.DateTimeFormat('de-DE', {year: 'numeric', month: 'long', day: 'numeric'}).format(new Date(article.meta.createdAt))}
                </Text>
                <TextLink href={article.page.pathname} decoration="inverted">
                    {'Weiter lesen'}
                </TextLink>
            </Box>
        </AspectRatio>
    );
};

export default MdxArticleCard;
