import React from 'react'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';
import Box from '../Box';

/**
 * This component should be used for any kind of heading. Size and Leading are
 * both handled by the `size` prop. Headings are rendered as `<h3>` but you can
 * change the underlying HTML element (for a proper document outline or SEO reasons) with the `as`
 * prop. When you don't specify any size this is rendered in the same size as
 * text.
 */
const Heading = React.forwardRef(({
    align,
    as,
    bold,
    children,
    color,
    italic,
    lineHeight,
    level,
    uppercase,
    sx,
    ...restProps
}, ref) => {
    const headingSize = {
        1: [6, 8],
        2: [5, 7],
        3: [4, 6],
        4: [3, 5],
        5: [2, 4],
        6: [2, 2]
    };

    const fontSize = Array.isArray(level)
        ? level.map(level => headingSize[level][1] || null)
        : headingSize[level] || null;

    return (
        <Box
            ref={ref}
            as={as || `h${level?.[0] || level || 4}`}
            sx={{
                textAlign: align || null,
                fontWeight: bold === true ? 'bold' : 'normal',
                textTransform: uppercase === true ? 'uppercase' : null,
                fontStyle: italic === true ? 'italic' : null,
                lineHeight: lineHeight,
                color: color,
                fontSize: fontSize,
                ...sx
            }}
            {...restProps}
        >
            {children}
        </Box>
    );
});

Heading.displayName = 'Heading';

Heading.propTypes = {
    align: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    as: PropTypes.string,
    bold: PropTypes.bool,
    color: PropTypes.string,
    italic: PropTypes.bool,
    lineHeight: PropTypes.string,
    /**
     * `level` is used to both adjust the size and the element wich is used for the heading
     * (unless you are changing it explicitely via the `as` property) - where `1` is rendered as `h1`
     * with the largest font-size
     */
    level: PropTypes.oneOfType([
        PropTypes.oneOf([1, 2, 3, 4, 5, 6]),
        PropTypes.arrayOf(PropTypes.oneOf([1, 2, 3, 4, 5, 6]))
    ]),
    uppercase: PropTypes.bool
};

Heading.defaultProps = {
    color: 'text',
    lineHeight: 'heading',
    bold: true
};

export default Heading;
