import React from 'react'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';

import Box from '../Box';
import Label from '../Label';

import {IconRadioChecked, IconRadioUnchecked} from '../../icons';

const RadioIcon = props => {
    return (
        <React.Fragment>
            <IconRadioChecked {...props} sx={{
                color: 'border',
                display: 'none',
                'input:checked ~ &': {
                    color: 'primary',
                    display: 'block'
                }
            }}
            />
            <IconRadioUnchecked {...props} sx={{
                display: 'block',
                color: 'border',
                'input:checked ~ &': {
                    display: 'none'
                }
            }}
            />
        </React.Fragment>
    );
};

const Radio = React.forwardRef(({as, color, children, space, ...restProps}, ref) => {
    return (
        <Box as={as || Label} display="flex" alignY="center">
            <Box
                ref={ref}
                as="input"
                type="radio"
                {...restProps}
                sx={{
                    position: 'absolute',
                    opacity: 0,
                    zIndex: -1,
                    width: 1,
                    height: 1,
                    overflow: 'hidden'
                }}
            />
            <RadioIcon aria-hidden="true" sx={{
                borderRadius: 9999,
                cursor: 'pointer',
                mr: space,
                color: 'border',
                'input:checked ~ &': {
                    color: color
                },
                'input:focus ~ &': {
                    outline: 0,
                    bg: 'rgba(66, 153, 225, 0.1)',
                    borderColor: '#63b3ed !important',
                    boxShadow: '0 0 0 3px rgba(66, 153, 225, 0.5)'
                },
                'input:disabled ~ &': {
                    color: 'border'
                }
            }}
            />
            {children}
        </Box>
    );
});

Radio.displayName = 'Radio';

Radio.propTypes = {
    /** as long as children are present we are applying a `margin-right` to the radio */
    space: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
        PropTypes.number
    ])
};

Radio.defaultProps = {
    space: 'xs',
    color: 'primary'
};

export default Radio;
