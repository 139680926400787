import React from 'react'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';

import Box from '../Box';
import Label from '../Label';
import {FlexCol} from '../Flex';

const Textarea = React.forwardRef((props, ref) => {
    const {id, label, bold, height, readOnly, ...restProps} = props;

    const inputField = (
        <Box
            as="textarea"
            id={id}
            ref={ref}
            readOnly={readOnly}
            minHeight={height}
            {...restProps}
            sx={{
                appearance: 'none',
                display: 'block',
                width: '100%',
                fontSize: 'inherit',
                fontFamily: 'inherit',
                lineHeight: 'inherit',
                border: '1px solid',
                borderColor: 'border',
                borderRadius: 'md',
                color: readOnly ? 'whisper' : 'inherit',
                bg: readOnly ? 'rgba(0,0,0,0.05)' : 'transparent',
                cursor: readOnly ? 'text' : null,
                p: 2,
                resize: 'vertical',
                '::placeholder': {
                    color: 'whisper',
                    opacity: 0.8
                }
            }}
        />
    );

    // Add label for textarea
    if (label) {
        return (
            <FlexCol space="sm">
                <Label htmlFor={id} bold={bold}>
                    {label}
                </Label>
                {inputField}
            </FlexCol>
        );
    }

    // Plain textarea
    return inputField;
});

Textarea.displayName = 'Textarea';

Textarea.propTypes = {
    /**
     * unique id for the textarea. when a label is provided this will automatically
     * be used as the `for` attribute
     */
    id: PropTypes.string.isRequired,
    /**
     * Label for the textarea. Make sure to skip this only when you are providing
     * an alternative label (and have a good reason to break our design
     * conventions around labels on top of inputs/textareas)
     */
    label: PropTypes.node,
    /**
     * minimum height of the textarea
     */
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /**
     * this controls wether the label should be bold or not
     */
    bold: PropTypes.bool
};

Textarea.defaultProps = {
    height: 12,
    bold: true
};

export default Textarea;
