import React from 'react';
import PropTypes from 'prop-types';
import Box from '../Box';

const cardShadows = {
    0: 'none',
    1: 'base',
    2: 'md',
    3: 'lg'
};

const resolveElevationShadow = (elevation) => Array.isArray(elevation)
    ? elevation.map(resolveElevationShadow)
    : cardShadows[elevation];

/**
 * Cards are used to box-off content and come with a fixed set of shadows depending on the
 * `elevation` property. `base` is used for the lowest level, visible container
 * which is drawn directly on the background canvas. Accepts all `<Box>` props for layouting.
 *
 * Some notes about the `elevation` property:
 *
 * - `0` is the lowest level and is rendered with a background of `canvas` and without any shadows.
 * - Think about nudging the `elevation` to `2` when using cards within cards.
 * - `bg` is automatically handled by setting the `elevation`, but can be overridden with `bg`
 * - The third elevation level (`elevation={3}`) should be reserved for global elements only like navbars or modals.
 */
const Card = React.forwardRef(({
    bg,
    children,
    elevation,
    ...restProps
}, ref) => {
    return (
        <Box ref={ref} bg={elevation === 0 ? 'canvas' : bg} boxShadow={resolveElevationShadow(elevation)} {...restProps}>
            {children}
        </Box>
    );
});

Card.displayName = 'Card';

Card.propTypes = {
    /** background color, defaults to `card` */
    bg: PropTypes.string,
    /** shadow size */
    elevation: PropTypes.oneOfType([
        PropTypes.oneOf([0, 1, 2, 3]),
        PropTypes.arrayOf(PropTypes.oneOf([0, 1, 2, 3]))
    ])
};

Card.defaultProps = {
    bg: 'card',
    elevation: 1
};

export default Card;