import React from 'react';
import Box from '../Box';
import PropTypes from 'prop-types';

/**
 * Circles with a fixed size and numbers / icons inside. Used for content
 * entities like Highlights / Tours or as a counter for steps, etc.
 */
const Circle = React.forwardRef(({size, bg, children, color, ...restProps}, ref) => {
    const sizes = {
        lg: {
            fontSize: 4,
            width: 12,
            height: 12
        },
        md: {
            fontSize: 2,
            width: 8,
            height: 8
        },
        sm: {
            fontSize: 1,
            width: 6,
            height: 6
        },
        xs: {
            fontSize: '8px',
            width: 4,
            height: 4,
            fontWeight: 'normal'
        },
        xxs: {
            fontSize: '8px',
            width: 2,
            height: 2,
            fontWeight: 'normal'
        }
    };

    const sizeStyles = sizes[size];
    const sharedStyles = {
        alignItems: 'center',
        bg,
        border: '1px solid transparent',
        color,
        borderRadius: 'full',
        display: 'inline-flex',
        fontWeight: 'bold',
        justifyContent: 'center',
        textAlign: 'center'
    };

    return (
        <Box
            ref={ref}
            {...restProps}
            sx={{
                ...sharedStyles,
                ...sizeStyles
            }}
        >
            {children}
        </Box>
    );
});

Circle.displayName = 'Circle';

Circle.propTypes = {
    /** Background Color */
    bg: PropTypes.string,
    /** Text Color */
    color: PropTypes.string,
    /** Badge Size */
    size: PropTypes.oneOf(['xxs', 'xs', 'sm', 'md', 'lg'])
};

Circle.defaultProps = {
    bg: 'primary',
    size: 'md',
    color: 'white'
};

export default Circle;
