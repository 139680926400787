/** @jsxRuntime classic */
/** @jsx jsx */
/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import { jsx, useThemeUI } from "theme-ui";

const IconTag = ({
  size,
  color,
  sx,
  width,
  height,
  viewBox,
  fill,
  xmln,
  ...props
}) => {
  const { theme } = useThemeUI();
  const iconSize = Array.isArray(size)
    ? size.map((size) => theme.sizes[size] || size)
    : theme.sizes[size] || size;
  const iconColor = Array.isArray(color)
    ? color.map((color) => theme.rawColors[color] || color)
    : theme.rawColors[color] || color;
  const iconStyle = {
    height: iconSize,
    width: iconSize,
    color: iconColor,
    ...sx,
  };
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={iconStyle}
      {...props}
    >
      <path
        d="M21 10.271l-.163-5.899a1.244 1.244 0 00-1.21-1.209l-5.898-.162c-.345-.01-.671.12-.914.363l-9.451 9.451a1.245 1.245 0 000 1.759l6.062 6.061a1.243 1.243 0 001.758 0l9.452-9.45c.24-.241.373-.574.364-.914zm-2.702-1.58a2.113 2.113 0 11-2.99-2.99 2.113 2.113 0 112.99 2.99z"
        fill="currentColor"
      />
    </svg>
  );
};

IconTag.propTypes = {
  size: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    ),
    PropTypes.number,
    PropTypes.string,
  ]),
  color: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
};
IconTag.defaultProps = {
  size: 6,
  color: "currentColor",
};
export default IconTag;
