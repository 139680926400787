module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-theme-ui@0.17.1_@emotion+react@11.14.0_@types+react@18.3.16_react@18.3.1__@mdx-_tzjgsynt7ooxy3wln27czzmy5m/node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.14.0_babel-eslint@10.1.0_eslint@7.32.0__react-dom@18.3.1_react@18.3.1__react@18.3.1__l2rqquvwremi2k5jv3rwjqms5q/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
