import React from 'react'; // eslint-disable-line no-unused-vars
import Box from '../Box';
import PropTypes from 'prop-types';

/**
 * Hidden allows you to display and hide elements based on our breakpoints
 */
const Hidden = React.forwardRef(({from, display, until, children}, ref) => {

    const untilByKeyword = {
        'sm': ['none', display],
        'md': ['none', null, display],
        'lg': ['none', null, null, display],
        'xl': ['none', null, null, null, display],
        '2xl': ['none', null, null, null, null, display],
        '3xl': ['none', null, null, null, null, null, display],
        '4xl': ['none', null, null, null, null, null, null, display]
    };

    const fromByKeyword = {
        'sm': [display, 'none'],
        'md': [display, null, 'none'],
        'lg': [display, null, null, 'none'],
        'xl': [display, null, null, null, 'none'],
        '2xl': [display, null, null, null, null, 'none'],
        '3xl': [display, null, null, null, null, null, 'none'],
        '4xl': [display, null, null, null, null, null, null, 'none']
    };

    const resolveDisplay = from ? fromByKeyword[from] : until ? untilByKeyword[until] : 'none';

    return (
        <Box ref={ref} display={resolveDisplay}>
            {children}
        </Box>
    );
});

Hidden.displayName = 'Hidden';

Hidden.propTypes = {
    /** display type when visible */
    display: PropTypes.string,
    /** hidden until */
    until: PropTypes.oneOf(['sm', 'md', 'lg', 'xl', '2xl', '3xl', '4xl']),
    /** hidden from */
    from: PropTypes.oneOf(['sm', 'md', 'lg', 'xl', '2xl', '3xl', '4xl'])
};

Hidden.defaultProps = {
    display: 'block'
};

export default Hidden;
