import React from 'react';
import PropTypes from 'prop-types';
import Box from '../Box';

/**
 * renders a full width line which is used to divide content.
 */
const Divider = React.forwardRef(({size, color, ...restProps}, ref) => {
    return (
        <Box
            ref={ref}
            sx={{
                borderBottomWidth: size, borderStyle: 'solid', borderColor: color, justifySelf: 'stretch'
            }}
            {...restProps}
        />
    );
});

Divider.displayName = 'Divider';

Divider.propTypes = {
    /**
   * color of the line
   */
    color: PropTypes.string,
    /**
   * size of the line
   */
    size: PropTypes.number
};

Divider.defaultProps = {
    size: 1,
    color: 'border'
};

export default Divider;
