import {useCallback, useEffect} from 'react';
import {substractRem, divideRem} from '../utils';

export const useSpaceMinusBorder = (theme) => {
    const remInPx = theme.fontSizes[theme.styles.root.fontSize];

    return useCallback((space, border) => {
        return substractRem(remInPx, theme.space[space] || space, theme.border[border] || border);
    }, [remInPx, theme]);
};

export const useSpaceDivideBy = (theme) => {
    const remInPx = theme.fontSizes[theme.styles.root.fontSize];

    return useCallback((space, by) => {
        return divideRem(remInPx, theme.space[space] || space, by);
    }, [remInPx, theme]);
};

/**
 * Run a callback when the user focuses/clicks outside of the element of `watchRef`.
 *
 * @param {React.MutableRefObject<HTMLElement>} watchRef Ref for element to watch
 * @param {function} onFocusOutside Callback to run when focusing outside
 * @param {React.DependencyList?} deps If present, the callback will only run when these change.
 */
export const useFocusOutside = (watchRef, onFocusOutside, deps) => {
    useEffect(() => {
        const handleCloseAction = (event) => {
            if (watchRef.current && !watchRef.current.contains(event.target)) {
                // Outside the menu, run callback
                onFocusOutside();
            }
        };

        document.addEventListener('mousedown', handleCloseAction);
        document.addEventListener('focusin', handleCloseAction);

        // Return the cleanup function
        return () => {
            document.removeEventListener('mousedown', handleCloseAction);
            document.removeEventListener('focusin', handleCloseAction);
        };
    }, deps); // Call the effect whenever deps items change
};