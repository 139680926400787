import React, {Fragment, useState} from 'react';

// Utils
import {debounce} from 'lodash';
import {useIsomorphicLayoutEffect} from '../../../hooks';
import {usePageContext} from '../../contexts';

// Components
import Menu from '../Menu';
import {FlexRow, IconButton, TextLink} from '../../../ui/components';
import {IconScrollToTop} from '../../../ui/icons';

const useScrollUp = (threshold) => {
    const [show, setShow] = useState(false);

    useIsomorphicLayoutEffect(() => {
        const scrollListener = debounce(() => {
            const thresholdValue = typeof threshold === 'string'
                ? /^(\d+)(vh|%)$/.test(threshold)
                    ? document.documentElement.clientHeight / 100 * parseFloat(threshold.replace(/^(\d+)(vh|%)$/, '$1'))
                    : document.documentElement.clientHeight // Fallback
                : threshold;

            const show = document.documentElement.scrollTop > thresholdValue;

            setShow(show);
        }, 200);

        scrollListener();

        window.addEventListener('scroll', scrollListener);
        window.addEventListener('resize', scrollListener);

        return () => {
            window.removeEventListener('scroll', scrollListener);
            window.removeEventListener('resize', scrollListener);
        };
    }, [threshold]);

    const handleScrollTop = (event) => {
        event?.preventDefault?.();

        document.documentElement.scroll({
            top: 0,
            behavior: 'smooth'
        });
    };

    return {
        showScrollUp: show,
        handleScrollTop
    };
};

const MdxFooter = () => {
    const {showScrollUp, handleScrollTop} = useScrollUp(300);

    const {aboutPages} = usePageContext();

    return (
        <Fragment>
            {/* Mobile screen with drop down menu */}
            <FlexRow
                width="100%"
                maxWidth="6xl"
                alignX="stretch"
                alignY="stretch"
                sx={{
                    display: ['none', 'inline-flex']
                }}
            >
                <FlexRow
                    alignX="left"
                    alignY="center"
                    space="md"
                >
                    {aboutPages.items.map(article => (
                        <TextLink
                            key={`link_${article.page.key}`}
                            href={article.page.pathname}
                            color="textOnFooter"
                            decoration="inverted"
                        >
                            {article.meta.title}
                        </TextLink>
                    ))}
                </FlexRow>
                {showScrollUp ? (
                    <IconButton
                        size="sm+"
                        variant="ghost"
                        color="textOnFooter"
                        icon={IconScrollToTop}
                        title="Zum Anfang scrollen"
                        showTitle={true}
                        onClick={handleScrollTop}
                    />
                ) : null}
            </FlexRow>
            {/* Larger screen with inline about pages links */}
            <FlexRow
                width="100%"
                maxWidth="6xl"
                alignX="stretch"
                alignY="stretch"
                sx={{
                    display: ['inline-flex', 'none']
                }}
            >
                <Menu
                    alignY="top"
                    color="textOnFooter"
                    menuColor="textOnFooter"
                    menuBg="footer"
                />
                {showScrollUp ? (
                    <IconButton
                        size="sm+"
                        variant="ghost"
                        color="textOnFooter"
                        icon={IconScrollToTop}
                        title="Zum Anfang scrollen"
                        showTitle={true}
                        onClick={handleScrollTop}
                    />
                ) : null}
            </FlexRow>
        </Fragment>
    );
};

export default MdxFooter;
