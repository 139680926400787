import React, {forwardRef, useEffect, useMemo, useRef, useState} from 'react'; // eslint-disable-line no-unused-vars

import Box from '../Box';
import Text from '../Text';

const clamp = (rootNode) => {
    const shouldContinue = () => {
        return rootNode.clientHeight < rootNode.scrollHeight;
    };

    const recursive = (node) => {
        if (shouldContinue()) {
            if (node.childNodes.length > 0) {
                // Get last children
                const lastChild = node.childNodes[node.childNodes.length - 1];

                // Process last child if it's a text node
                if (lastChild.nodeType === 3) {
                    // Get raw text, but remove trailing space and punctuation
                    const rawText = lastChild.textContent
                        .replace(/[\n\r\s\.\?!-,;]+$/, '')
                        .replace(/\s*…$/, '');

                    // Remove node without content
                    if (rawText.length === 0) {
                        const parentNode = lastChild.parentNode;

                        parentNode.removeChild(lastChild);

                        // Continue on parent node (check previous children)
                        return recursive(parentNode);
                    }

                    // Split into words
                    const words = rawText.split(/( |\.|\?|\!|-|,|;)/);

                    // Remove trailing empty strings
                    while (words[words.length - 1] === '') {
                        words.pop();
                    }

                    // Remove last word
                    words.pop();

                    // Remove trailing space and punctuation and add ellipses
                    const clampedText = words
                        .join('')
                        .replace(/[\n\r\s\.\?!-,;]+$/, '')
                        .concat('…');

                    // Replace text on node
                    lastChild.textContent = clampedText;

                    // Continue on parent node (check last children again)
                    return recursive(lastChild.parentNode);
                }

                // Continue on current last child (check for its children)
                return recursive(lastChild);
            }

            // Remove node if it's not the root node
            if (node !== rootNode) {
                const parentNode = node.parentNode;

                parentNode.removeChild(node);

                // Continue on parent node
                return recursive(parentNode);
            }
        }
    };

    recursive(rootNode);
};

const TextClamp = forwardRef(({children, ...props}, ref) => {
    // Reference of the DOMNode
    const nodeRef = useRef();

    // Get a refresh key to force re-render of children on resize or children change
    const [refresh, setRefresh] = useState(1);
    const refreshRef = useRef(refresh);
    refreshRef.current = useMemo(() => {
        return refreshRef.current + 1;
    }, [children, refresh]);

    // Clamp and observe size changes
    useEffect(() => {
        const node = (ref || nodeRef)?.current;

        if (node) {
            clamp(node);

            // Notice last width/height
            const lastWidth = node.clientWidth;
            const lastHeight = node.clientHeight;

            const resizeObserver = new ResizeObserver((entries) => {
                // Read current width/height
                const currWidth = entries[0]?.contentRect.width ?? lastWidth;
                const currHeight = entries[0]?.contentRect.height ?? lastHeight;

                // Check whether width/height changed
                if (Math.abs(lastWidth - currWidth) > 1 || Math.abs(lastHeight - currHeight) > 1) {
                    // Force fresh render
                    setRefresh(refreshRef.current  + 1);
                }
            });

            // Start observing
            resizeObserver.observe(node);

            return () => {
                // Stop observing
                resizeObserver.disconnect();
            };
        }
    }, [children, refresh]);

    return (
        <Box ref={ref || nodeRef} {...props} key={`refresh_${refreshRef.current}`}>
            {children}
        </Box>
    );
});

TextClamp.defaultProps = {
    as: Text
};

export default TextClamp;
