const colors = {
    canvas: '#00263e',

    sky: '#0091EB',
    sunflower: '#FFCF00',
    strawberry: '#E55252',
    raspberry: '#D64040',
    grass: '#33582F',
    ocean: '#0077D9',

    white: '#FFFFFF',
    almostWhite: '#F2F2F2',
    lighterGrey: '#E4E4E4',
    lightGrey: '#CCCCCC',
    grey: '#888888',
    darkGrey: '#666666',
    darkerGrey: '#383838',
    almostBlack: '#151515',
    black: '#000000'
};

const sizeScale = {
    '0': '0',
    '1': '0.25rem',
    '2': '0.5rem',
    '3': '0.75rem',
    '4': '1rem',
    '5': '1.25rem',
    '6': '1.5rem',
    '8': '2rem',
    '10': '2.5rem',
    '12': '3rem',
    '16': '4rem',
    '20': '5rem',
    '24': '6rem',
    '32': '8rem',
    '40': '10rem',
    '48': '12rem',
    '56': '14rem',
    '64': '16rem',
    '68': '18rem',
    '72': '20rem'
};

const whiteSpace = {
    'none': '0',
    'px': `${1 / 16}rem`, // 1px
    'xxs': sizeScale[1],
    'xs': sizeScale[2],
    'sm': sizeScale[3],
    'md': sizeScale[6],
    'lg': sizeScale[12],
    'xl': sizeScale[24],
    'xxl': sizeScale[48]
};

const containers = {
    xxs: '16rem',
    xs: '20rem',
    sm: '24rem',
    md: '28rem',
    lg: '32rem',
    xl: '36rem',
    '2xl': '42rem',
    '3xl': '48rem',
    '4xl': '56rem',
    '5xl': '64rem',
    '6xl': '72rem',
    '7xl': '80rem',
    '8xl': '90rem',
    '9xl': '100rem',
    '10xl': '110rem'
};

const columns = {
    '1/2': '50%',
    '1/3': '33.333333%',
    '2/3': '66.666667%',
    '1/4': '25%',
    '2/4': '50%',
    '3/4': '75%',
    '1/5': '20%',
    '2/5': '40%',
    '3/5': '60%',
    '4/5': '80%',
    '1/6': '16.666667%',
    '2/6': '33.333333%',
    '3/6': '50%',
    '4/6': '66.666667%',
    '5/6': '83.333333%',
    '1/12': '8.333333%',
    '2/12': '16.666667%',
    '3/12': '25%',
    '4/12': '33.333333%',
    '5/12': '41.666667%',
    '6/12': '50%',
    '7/12': '58.333333%',
    '8/12': '66.666667%',
    '9/12': '75%',
    '10/12': '83.333333%',
    '11/12': '91.666667%'
};

const border = {
    'px': `${1 / 16}rem`, // 1px
    '1': `${1 / 16}rem`, // 1px
    '2': `${2 / 16}rem`, // 2px
    '3': `${3 / 16}rem` // 2px
};

const sizes = {
    ...sizeScale,
    ...containers,
    ...columns
};

const space = {
    ...sizeScale,
    ...whiteSpace
};

const zIndices = {
    auto: 'auto',
    negative: '-1',
    '0': 0,
    '1': 100,
    '2': 200,
    '3': 300,
    '4': 400,
    '5': 500,
    '6': 600,
    '7': 700,
    '8': 800,
    '9': 900
};

export default {
    config: {
        useColorSchemeMediaQuery: 'system'
    },
    breakpoints: [
        '540px',
        '720px',
        '1024px',
        '1280px',
        '1440px',
        '1680px',
        '1920px'
    ],
    colors: {
        ...colors,

        background: colors.canvas, // Required by theme-ui as a fallback for the page
        textOnBackground: colors.white,

        header: colors.sky,
        textOnHeader: colors.white,

        footer: colors.sunflower,
        textOnFooter: colors.almostBlack,

        primary: colors.sky,
        textOnPrimary: colors.white,

        border: colors.lightGrey,
        separator: colors.lighterGrey,
        disabled: colors.lightGrey,

        muted: colors.almostWhite,
        card: colors.white,
        tooltip: colors.darkerGrey,

        danger: colors.strawberry,
        error: colors.raspberry,
        success: colors.grass,
        info: colors.ocean,
        like: colors.raspberry,

        text: colors.almostBlack,
        whisper: colors.darkGrey,
        textOnDark: colors.white,

        // Special 3rd party colors
        komootButtonOnCard: '#5f850d',
        komootButtonTextOnCard: colors.white,
        vimeoButtonOnCard: '#00ADEF',
        vimeoButtonTextOnCard: 'white',
        twitterButtonOnCard: '#1d9bf0',
        twitterButtonTextOnCard: colors.white,

        modes: {
            dark: {
                primary: colors.sunflower,
                textOnPrimary: colors.almostBlack,

                border: colors.darkerGrey,

                card: colors.almostBlack,
                text: colors.white,
                whisper: colors.grey,

                // Special 3rd party colors
                komootButtonOnCard: '#9cde4e',
                komootButtonTextOnCard: colors.almostBlack
            }
        }
    },
    fonts: {
        heading: 'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
        body: 'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
        monospace: 'Menlo, monospace'
    },
    fontWeights: {
        body: 400,
        heading: 700,
        bold: 700
    },
    lineHeights: {
        body: 1.5,
        heading: 1.125
    },
    fontSizes: [12, 14, 16, 18, 20, 24, 28, 36, 48, 64, 72],
    radii: {
        none: '0',
        sm: '4px',
        md: '8px',
        lg: '16px',
        full: '9999em'
    },
    shadows: {
        base: '0 1px 2px rgba(0,0,0,0.2)',
        md: '0px 4px 8px rgba(0,0,0,0.1)',
        lg: '0px 8px 24px rgba(0,0,0,0.2)',
        inner: 'inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)',
        stacked: '0px 2px 10px rgba(0,0,0,0.06),1px 2px 12px rgba(0,0,0,0.1)',
        none: 'none'
    },
    sizes,
    space,
    border,
    zIndices,
    styles: {
        root: {
            fontFamily: 'body',
            fontSize: 2,
            lineHeight: 'body',
            color: 'text',
            bg: 'canvas'
        }
    }
};
