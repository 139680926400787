
import React from 'react';

// Utils
import { useMDXContentContext } from '../../contexts/MDXContentContext';

const createMDXContentResolverComponent = (tagName) => {
    const MDXContentResolver = ({as = tagName, ...props}) => {
        const components = useMDXContentContext();

        const Component = components[as];

        if (Component) {
            return (
                <Component {...props}/>
            );
        }

        return null;
    };

    if (tagName) {
        MDXContentResolver.displayName = `MDXContentResolver.${tagName}`;
    }

    return MDXContentResolver;
}

export const MDXContentResolver = createMDXContentResolverComponent();

MDXContentResolver.components = {
    h1: createMDXContentResolverComponent('h1'),
    h2: createMDXContentResolverComponent('h2'),
    h3: createMDXContentResolverComponent('h3'),
    h4: createMDXContentResolverComponent('h4'),
    h5: createMDXContentResolverComponent('h5'),
    h6: createMDXContentResolverComponent('h6'),
    p: createMDXContentResolverComponent('p'),
    a: createMDXContentResolverComponent('a'),
    ol: createMDXContentResolverComponent('ol'),
    ul: createMDXContentResolverComponent('ul'),
    li: createMDXContentResolverComponent('li'),
    em: createMDXContentResolverComponent('em'),
    strong: createMDXContentResolverComponent('strong'),
    blockquote: createMDXContentResolverComponent('blockquote'),
    table: createMDXContentResolverComponent('table'),
    thead: createMDXContentResolverComponent('thead'),
    tr: createMDXContentResolverComponent('tr'),
    th: createMDXContentResolverComponent('th'),
    tbody: createMDXContentResolverComponent('tbody'),
    td: createMDXContentResolverComponent('td')
};
