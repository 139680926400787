import React from 'react'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';
import Box from '../Box';
import {keyframes} from '@emotion/react';

const bounce = keyframes({
    '0%, 80%, 100%': {transform: 'scale(0)'},
    '40%': {transform: 'scale(1)'}
});

const Dot = ({color, sx, ...restProps}) => {
    return (
        <Box
            display="inline-flex"
            bg={color}
            width={2}
            height={2}
            mr={1}
            borderRadius="full"
            css={{
                animationName: bounce.toString(),
                animationTimingFunction: 'ease-in-out',
                animationDuration: '1400ms',
                animationDelay: '0s',
                animationIterationCount: 'infinite',
                animationFillMode: 'both'
            }}
            sx={sx}
            {...restProps}
        />
    );
};

/**
 * Bouncing Dots animation to use as loading state
 * loosely based on [spinkit](https://github.com/tobiasahlin/SpinKit)
*/
const LoadingBounce = React.forwardRef(({color}, ref)=> {
    return (
        <Box
            ref={ref}
            display="inline-flex"
            alignX="justify-between"
            alignY="center"
            mr={-1}
        >
            <Dot color={color} sx={{opacity: '0.3', animationDelay: '-0.32s'}}/>
            <Dot color={color} sx={{opacity: '0.6', animationDelay: '-0.16s'}}/>
            <Dot color={color}/>
        </Box>
    );
});

LoadingBounce.displayName = 'LoadingBounce';

LoadingBounce.propTypes = {
    color: PropTypes.string
};

LoadingBounce.defaultProps = {
    color: 'whisper'
};

export default LoadingBounce;
