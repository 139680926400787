import React from 'react';
import PropTypes from 'prop-types';

import IconButton from '../IconButton';
import DropdownMenu from '../DropdownMenu';

import {IconMore} from '../../icons';

const OverflowMenu = React.forwardRef((props, ref) => {
    const {children, color, onClick, ...restProps} = props;

    // Expose onClick action while handling open menu internally
    const handleOpenMenu = (event, openMenu) => {
        openMenu(event);

        if (onClick) {
            onClick(event);
        }
    };

    return (
        <DropdownMenu
            trigger={openMenu => (
                <IconButton
                    data-test-id={props['data-test-id']}
                    variant="ghost"
                    color={color}
                    icon={IconMore}
                    onClick={event => handleOpenMenu(event, openMenu)}
                />
            )}
            ref={ref}
            {...restProps}
        >
            {children}
        </DropdownMenu>
    );
});

OverflowMenu.displayName = 'OverflowMenu';

OverflowMenu.propTypes = {
    /** color of the `<IconButton/>` */
    color: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    /** shadow size */
    elevation: PropTypes.oneOf([1, 2, 3]),
    /** menu align */
    align: PropTypes.oneOf(['left', 'right']),
    /** z-index of the menu */
    zIndex: PropTypes.oneOf(['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'])
};

export default OverflowMenu;
