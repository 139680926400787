import React, {Children, useMemo} from 'react';

import flattenChildren from 'react-keyed-flatten-children';
import PropTypes from 'prop-types';
import {uniq, keys} from 'lodash';
import {dependencyKey} from '../../utils';

import Box from '../Box';


// check taken from https://github.com/Modernizr/Modernizr/blob/master/feature-detects/css/flexgap.js
function checkFlexGapSupport() {
    if (typeof window === 'undefined') {
        return true;
    }

    // create flex container with row-gap set
    const flex = document.createElement('div');
    flex.style.display = 'flex';
    flex.style.flexDirection = 'column';
    flex.style.rowGap = '1px';

    // create two elements inside it
    flex.appendChild(document.createElement('div'));
    flex.appendChild(document.createElement('div'));

    // append to the DOM (needed to obtain scrollHeight)
    document.body.appendChild(flex);
    const isSupported = flex.scrollHeight === 1; // flex container should be 1px high from the row-gap
    flex.parentNode.removeChild(flex);

    return isSupported;
}


const resolveBreakpoints = (defaults, props) => {
    const resolvedProps = {};

    let maxLength = 0;

    const uniqKeys = uniq([
        ...keys(defaults),
        ...keys(props)
    ]);

    uniqKeys.forEach(key => {
        resolvedProps[key] = Array.isArray(props[key])
            ? props[key]
            : props[key] !== undefined
                ? [props[key]]
                : [defaults[key]];

        if (resolvedProps[key].length > maxLength) {
            maxLength = resolvedProps[key].length;
        }
    });

    const resolvedBreakpoints = [];

    for (let i = 0; i < maxLength; i++) {
        const breakpoint = {};

        uniqKeys.forEach(key => {
            breakpoint[key] = resolvedProps[key][Math.min(i, resolvedProps[key].length - 1)];
        });

        resolvedBreakpoints.push(breakpoint);
    }

    return resolvedBreakpoints;
};

const DEFAULTS = {
    variant: 'inline',
    reverse: false,
    dividers: false,
    space: 'md',
    alignX: 'start',
    alignY: 'end'
};

const resolveDisplay = ({variant}) => variant === 'column' ? 'flex' : 'inline-flex';
const resolveAlignItems = ({variant, alignX, alignY}) => {
    const alignMapping = {
        top: 'start',
        left: 'start',
        center: 'center',
        stretch: 'stretch',
        right: 'end',
        bottom: 'end'
    };

    return variant === 'column'
        ? alignMapping[alignX] || alignX
        : alignMapping[alignY] || alignY;
};
const resolveJustifyContent = ({variant, alignX, alignY}) => {
    const alignMapping = {
        top: 'start',
        left: 'start',
        center: 'center',
        stretch: 'space-between',
        right: 'end',
        bottom: 'end'
    };

    return variant === 'column'
        ? alignMapping[alignY] || alignY
        : alignMapping[alignX] || alignX;
};
const resolveAlignSelf = ({variant, width, alignSelf}) => {
    const alignMapping = {
        top: 'start',
        left: 'start',
        center: 'center',
        stretch: 'stretch',
        right: 'end',
        bottom: 'end'
    };

    return alignSelf
        ? alignMapping[alignSelf] || alignSelf
        : width
            ? 'unset'
            : variant === 'inline'
                ? 'unset'
                : 'stretch';
};
const resolveWidth = ({variant, width}) => {
    return width === 'content'
        ? 'unset'
        : width
            ? width
            : variant === 'inline'
                ? 'unset'
                : 'initial';
};
const resolveFlexDirection = ({variant, reverse}) => {
    if (variant === 'column') {
        return reverse ? 'column-reverse' : 'column';
    }
    return reverse ? 'row-reverse' : 'row';
};
const resolveFlexShrink = ({variant, width}) => {
    return width === 'content'
        ? 0
        : width
            ? 0
            : variant === 'inline'
                ? 0
                : undefined;
};
const resolveFlexWrap = ({variant, flexWrap}) => {
    return flexWrap
        ? flexWrap
        : variant === 'inline'
            ? 'wrap'
            : undefined;
};

const resolveDevidersDisplay = ({dividers}) => {
    return dividers ? 'flex' : 'none';
};
const resolveDevidersWidth = ({variant, dividers}) => {
    if (!dividers || variant === 'row' || variant === 'inline') {
        return undefined;
    }

    return dividers === true
        ? '100%'
        : dividers;
};
const resolveDevidersHeight = ({variant, dividers}) => {
    if (!dividers || variant === 'column') {
        return undefined;
    }

    return dividers === true
        ? 8
        : dividers;
};
const resolveDevidersBorderBottomWidth = ({variant, dividers}) => {
    return variant === 'column' && dividers ? '1px' : '0';
};
const resolveDevidersBorderLeftWidth = ({variant, dividers}) => {
    return variant !== 'column' && dividers ? '1px' : '0';
};

const useFlex = ({as, ...props}) => {
    return useMemo(() => {
        const isFlexGapSupported = checkFlexGapSupport();

        const isList = as === 'ol' || as === 'ul';

        const resolvedBreakpoints = resolveBreakpoints(DEFAULTS, props);

        const flexSx = {
            display: [],
            alignItems: [],
            justifyContent: [],
            alignSelf: [],
            width: [],
            gap: [],
            flexDirection: [],
            flexShrink: [],
            flexWrap: [],
            listStyleType: isList ? 'none' : null
        };

        if (!isFlexGapSupported) {
            // add margins margins & margin-placeholders for the flex gap polyfill below
            flexSx['& > *'] = {
                marginRight: [],
                marginBottom: [],
                '&:last-child': {
                    marginRight: 0,
                    marginBottom: 0
                }
            };
        }


        const dividerSx = {
            display: [],
            width: [],
            height: [],
            borderBottomWidth: [],
            borderLeftWidth: []
        };

        resolvedBreakpoints.forEach(resolved => {
            flexSx.display.push(resolveDisplay(resolved));
            flexSx.alignItems.push(resolveAlignItems(resolved));
            flexSx.justifyContent.push(resolveJustifyContent(resolved));
            flexSx.alignSelf.push(resolveAlignSelf(resolved));
            flexSx.width.push(resolveWidth(resolved));

            if (isFlexGapSupported) {
                flexSx.gap.push(resolved.space);
            } else {
                // simple Polyfill - may not be perfect in some cases
                if (resolved.variant === 'row' || resolved.variant === 'inline') {
                    flexSx['& > *'].marginRight.push(resolved.space);
                    flexSx['& > *'].marginBottom.push(0);
                }
                if (resolved.variant === 'column') {
                    flexSx['& > *'].marginBottom.push(resolved.space);
                }
            }

            flexSx.flexDirection.push(resolveFlexDirection(resolved));
            flexSx.flexShrink.push(resolveFlexShrink(resolved));
            flexSx.flexWrap.push(resolveFlexWrap(resolved));

            dividerSx.display.push(resolveDevidersDisplay(resolved));
            dividerSx.width.push(resolveDevidersWidth(resolved));
            dividerSx.height.push(resolveDevidersHeight(resolved));
            dividerSx.borderBottomWidth.push(resolveDevidersBorderBottomWidth(resolved));
            dividerSx.borderLeftWidth.push(resolveDevidersBorderLeftWidth(resolved));
        });

        return {flexSx, dividerSx};
    }, [as, dependencyKey(props)]);
};

/**
 * Flex is a layout component to arange elements vertically or horizontally and control the white space between them
 */
const Flex = React.forwardRef(({
    children,
    as,
    variant,
    reverse,
    dividers,
    width,
    space,
    alignX,
    alignY,
    alignSelf,
    flexWrap,
    ...restProps
}, ref) => {
    const {flexSx, dividerSx} = useFlex({
        as,
        variant,
        reverse,
        dividers,
        width,
        space,
        alignX,
        alignY,
        alignSelf,
        flexWrap
    });

    return (
        <Box
            ref={ref}
            as={as}
            {...restProps}
            sx={{
                ...flexSx,
                ...(restProps.sx || {}) // Allow overwriting generated sx
            }}
        >
            {Children.map(flattenChildren(children), (child, i) => (
                <>
                    {i !== 0 && dividers ? (
                        <Box
                            sx={{
                                borderStyle: 'solid',
                                borderColor: 'border',
                                ...dividerSx
                            }}
                        />
                    ) : null}
                    {child}
                </>
            ))}
        </Box>
    );
});

Flex.displayName = 'Flex';

Flex.propTypes = {
    /**
     * variant of the flex component
     */
    variant: PropTypes.oneOfType([
        PropTypes.oneOf(['column', 'row', 'inline']),
        PropTypes.arrayOf(PropTypes.oneOf(['column', 'row', 'inline']))
    ]).isRequired,
    /**
     * controls the vertical alignment of the items
     */
    alignX: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string)
    ]),
    /**
     * controls the horizontal alignment of the items
     */
    alignY: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string)
    ]),
    /**
     * changes how items are spaced out
     */
    space: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.arrayOf(PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]))
    ]),
    /**
     * reverse items order
     */
    reverse: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.arrayOf(PropTypes.bool)
    ]),
    /**
     * dividers in-between items; boolean: default size; number or string: size from theme
     */
    dividers: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string,
        PropTypes.number,
        PropTypes.arrayOf(PropTypes.oneOfType([
            PropTypes.bool,
            PropTypes.string,
            PropTypes.number
        ]))
    ])
};

Flex.defaultProps = {
    space: 'md',
    dividers: false,
    reverse: false
};

/**
 * FlexCol short handle for <Flex variant="column"/>
 */
const FlexCol = React.forwardRef((props, ref) => {
    return (
        <Flex
            ref={ref}
            variant="column"
            {...props}
        />
    );
});

FlexCol.defaultProps = {
    ...Flex.defaultProps
};
FlexCol.propTypes = {
    ...Flex.PropTypes,
    variant: () => { }
};

/**
 * FlexRow short handle for <Flex variant="row"/>
 */
const FlexRow = React.forwardRef((props, ref) => {
    return (
        <Flex
            ref={ref}
            variant="row"
            {...props}
        />
    );
});

FlexRow.defaultProps = {
    ...Flex.defaultProps
};
FlexRow.propTypes = {
    ...Flex.PropTypes,
    variant: () => { }
};

/**
 * FlexInline short handle for <Flex variant="inline"/>
 */
const FlexInline = React.forwardRef((props, ref) => {
    return (
        <Flex
            ref={ref}
            variant="inline"
            {...props}
        />
    );
});

FlexInline.defaultProps = {
    ...Flex.defaultProps
};
FlexInline.propTypes = {
    ...Flex.PropTypes,
    variant: () => { }
};

export default Flex;
export {FlexCol, FlexRow, FlexInline};
