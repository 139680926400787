import React from 'react'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';
import Box from '../Box';

export const sizeFromProp = (prop) =>
    ({
        sm: 1,
        md: 2,
        lg: 3
    }[prop] || prop);

/**
 * Use this component for all texts and body copy. Defaults to a `<p>` but can be used as any HTML element.
 */
const Text = React.forwardRef(({
    as,
    align,
    bold,
    children,
    color,
    italic,
    lineHeight,
    size,
    uppercase,
    sx,
    ...restProps
}, ref) => {
    const resolveFontSize = (size) =>
        Array.isArray(size) ? size.map(sizeFromProp) : sizeFromProp(size);
    return (
        <Box
            ref={ref}
            as={as}
            {...restProps}
            sx={{
                textTransform: uppercase === true ? 'uppercase' : null,
                textAlign: align || null,
                fontWeight: bold === true ? 'bold' : bold === false ? 'normal' : null,
                fontStyle: italic === true ? 'italic' : italic === false ? 'normal' : null,
                lineHeight,
                color,
                fontSize: resolveFontSize(size),
                ...sx
            }}
        >
            {children}
        </Box>
    );
});

Text.displayName = 'Text';

Text.propTypes = {
    align: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    as: PropTypes.string,
    bold: PropTypes.bool,
    color: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    italic: PropTypes.bool,
    lineHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    /**
     * responsive prop. all ui text should be set in 3 distinctive sizes. if you
     * really need to have a custom size pick it from our font-size
     * scale which uses numbers instead of strings
     */
    size: PropTypes.oneOfType([PropTypes.number, PropTypes.array, PropTypes.string, PropTypes.oneOf(['xs', 'sm', 'md', 'lg'])]),
    uppercase: PropTypes.bool,
    sx: PropTypes.object
};

Text.defaultProps = {
    as: 'p',
    size: 'md',
    color: 'text'
};

export default Text;
