import React from 'react';

// Utils
import {toIndexPageNHref, toTagNHref} from '../../utils';

// Components
import {Button, FlexRow, Heading} from '../../../ui/components';
import {IconArrowBack, IconArrowForward} from '../../../ui/icons';

const MdxArticlePagination = ({
    tag,
    title,
    titleAs = 'h1',
    pageInfo,
    color = 'textOnBackground',
    ...restProps
}) => {
    const toPaginationUrl = (i) => {
        return tag
            ? toTagNHref(tag, i)
            : toIndexPageNHref(i);
    };

    return (
        <FlexRow
            alignX={pageInfo.hasNextPage || pageInfo.hasPreviousPage ? 'space-between' : 'center'}
            alignY="center"
            {...restProps}
        >
            {pageInfo.hasNextPage || pageInfo.hasPreviousPage ? (
                <FlexRow
                    width="content"
                    minWidth={['48px', '48px', '170px']}
                    alignX="left"
                    alignY="center"
                >
                    {pageInfo.hasNextPage ? (
                        <Button
                            variant="ghost"
                            icon={IconArrowBack}
                            showLabel={[false, false, true]}
                            pill={true}
                            color={color}
                            href={toPaginationUrl(pageInfo.currentPage + 1)}
                        >
                            {`Ältere Artikel`}
                        </Button>
                    ) : null}
                </FlexRow>
            ) : null}

            {title ? (
                <Heading
                    as={titleAs}
                    level={[5, 4, 3]}
                    align="center"
                    color={color}
                >
                    {title}
                </Heading>
            ) : null}

            {pageInfo.hasNextPage || pageInfo.hasPreviousPage ? (
                <FlexRow
                    width="content"
                    minWidth={['48px', '48px', '170px']}
                    alignX="right"
                    alignY="center"
                >
                    {pageInfo.hasPreviousPage ? (
                        <Button
                            variant="ghost"
                            iconRight={IconArrowForward}
                            showLabel={[false, false, true]}
                            pill={true}
                            color={color}
                            href={toPaginationUrl(pageInfo.currentPage - 1)}
                        >
                            {`Neuere Artikel`}
                        </Button>
                    ) : null}
                </FlexRow>
            ) : null}
        </FlexRow>
    );
};

export default MdxArticlePagination;
