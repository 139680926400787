import {useState, useCallback, useRef} from 'react';

export const useForceRender = () => {
    const [count, setCount] = useState(0);
    const countRef = useRef(count);
    countRef.current = count;

    const forceRender = useCallback(() => {
        setCount(countRef.current + 1);
    }, [countRef, setCount]);

    return forceRender;
};
