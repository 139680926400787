import {useCallback, useEffect, useState} from 'react';
import {useIsomorphicLayoutEffect} from './useIsomorphicLayoutEffect';

export const useThirdPartyConsent = (id) => {

    // GDPR Consent state
    const [consent, setConsent] = useState(false);

    // Read from session storage after hydration
    useIsomorphicLayoutEffect(() => {
        let readConsent = false;

        try {
            readConsent = window.sessionStorage.getItem(`consent:${id}`) === 'true';
        } catch {}

        setConsent(readConsent);
    }, [id]);

    // Event based sync
    useEffect(() => {
        const handleConsent = () => {
            setConsent(true);

            try {
                window.sessionStorage.setItem(`consent:${id}`, 'true');
            } catch {}
        };

        window.addEventListener(`consent:${id}`, handleConsent);

        return () => {
            window.removeEventListener(`consent:${id}`, handleConsent);
        };
    }, [id]);

    const handleConsent = useCallback((event) => {
        event.preventDefault();

        window.dispatchEvent(new CustomEvent(`consent:${id}`));
    }, [id]);

    return [consent, handleConsent];
};
