import React from 'react'; // eslint-disable-line no-unused-vars
import Box from '../Box';
import PropTypes from 'prop-types';
import IconButton from '../IconButton';
import {IconSearch, IconClose} from '../../icons';
import {LoadingSpinner} from '..';

/**
 * `<Search/>` renders an input inside a form as translucent search bar.
 */
const Search = React.forwardRef((props, ref) => {
    const {variant, onReset, onSubmit, inProgress, ...restProps} = props;

    const variants = {
        default: {
            bg: 'rgba(0,0,0,0.10)',
            color: 'text',
            '::placeholder': {
                color: 'whisper',
                opacity: 0.8
            }
        },
        onDark: {
            bg: 'rgba(255,255,255,0.2)',
            color: 'white',
            ':hover, :focus': {
                bg: 'rgba(255,255,255,0.25)'
            },
            '::placeholder': {
                color: 'textOnDark',
                opacity: 0.8
            }
        }
    };

    const variantStyles = variants[variant];

    const sharedStyles = {
        appearance: 'none',
        display: 'block',
        width: '100%',
        fontSize: 2,
        fontFamily: 'inherit',
        lineHeight: 'inherit',
        minHeight: 10,
        textOverflow: 'ellipsis',
        border: '1px solid',
        borderColor: 'transparent',
        borderRadius: 'md',
        pl: 10,
        pr: 10
    };

    return (
        <Box as="form" position="relative" onSubmit={onSubmit}>
            <Box
                position="absolute"
                left={0}
                top={0}
                bottom={0}
                px="2"
                display="flex"
                alignY="center"
                sx={{
                    color: variant === 'onDark' ? 'white' : 'whisper'
                }}
            >
                {inProgress ? <LoadingSpinner/> : <IconSearch aria-hidden="true" pointerEvents="none"/>}
            </Box>
            <Box as="input" ref={ref} {...restProps} sx={{
                ...sharedStyles,
                ...variantStyles
            }}
            />
            {onReset && restProps.value?.length > 0 && (
                <Box
                    position="absolute"
                    px="2"
                    right={0}
                    top={0}
                    bottom={0}
                    display="flex"
                    alignY="center"
                >
                    <IconButton variant="ghost" color={variant === 'onDark' ? 'white' : 'whisper'} icon={IconClose} onClick={onReset}/>
                </Box>
            )}
        </Box>
    );
});

Search.displayName = 'Search';

Search.propTypes = {
    /**
     * Search Action
     */
    onSubmit: PropTypes.func,
    /**
     * Action to reset the search
     */
    onReset: PropTypes.func,
    /**
     * the `default` variant renders the input in black with transparency applied. use `onDark` on darker backgrounds to render it in transparent white instead.
     */
    variant: PropTypes.oneOf(['default', 'onDark'])
};

Search.defaultProps = {
    variant: 'default'
};

export default Search;
