import React from 'react';
import PropTypes from 'prop-types';
import Box from '../Box';

/**
 * Use this component for anchor links. Automatically applies a top padding to
 * get nicer spacing around the scroll position. We might want to switch to
 * `scroll-margin-top` (see [MDN docs](https://developer.mozilla.org/de/docs/Web/CSS/scroll-margin))
 * and remove the custom element, once the browser support gets a bit better.
 */
const Anchor = React.forwardRef(({id, children}, ref) => {
    return (
        <>
            <Box
                ref={ref}
                id={id}
                sx={{
                    display: 'block',
                    position: 'relative',
                    top: '-120px',
                    visibility: 'hidden'
                }}
            />
            {children}
        </>
    );
});

Anchor.displayName = 'Anchor';

Anchor.propTypes = {
    /** id of the anchor. this is used when referencing the anchor link */
    id: PropTypes.string.isRequired
};

export default Anchor;
