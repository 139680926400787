import React from 'react';
import {alpha, shade} from '@theme-ui/color';
import PropTypes from 'prop-types';

import CoreButton from '../CoreButton';

/**
 * `<IconButton/>` is used for close actions or when there's only limited space
 * available. Consider using Buttons with a label (e.g. with the "link" variant)
 * first and only make use of this component sparsely.
 */
const IconButton = React.forwardRef(({
    variant,
    icon,
    isDisabled,
    color,
    href,
    pill,
    onClick,
    onMouseDown,
    onMouseUp,
    size,
    sx,
    ...restProps
}, ref) => {
    const colorButton = isDisabled
        ? ['disabled']
        : Array.isArray(color)
            ? color
            : color
                ? [color]
                : [];

    const variants = {
        transparent: {
            bg: 'rgba(0,0,0,0.7)',
            borderRadius: pill === true ? 'full' : 'md',
            color: 'white',
            ':hover, :focus': isDisabled ? null : {
                bg: 'rgba(0,0,0,0.9)'
            }
        },
        ghost: {
            bg: 'transparent',
            borderRadius: pill === true ? 'full' : 'md',
            color: colorButton,
            ':hover, :focus': isDisabled ? null : {
                color: colorButton.map(colorButton => shade(colorButton, 0.05)),
                bg: colorButton.map(colorButton => alpha(colorButton, 0.1))
            }
        },
        plain: {
            bg: 'transparent',
            color: colorButton,
            p: 0,
            ':hover, :focus': isDisabled ? null : {
                textDecoration: 'underline',
                color: colorButton.map(colorButton => shade(colorButton, 0.05))
            }
        }
    };

    const variantStyles = variants[variant];

    return (
        <CoreButton
            ref={ref}
            as={href ? 'a' : 'button'}
            size={size}
            iconLeft={icon}
            type={href ? undefined : 'button'}
            href={href}
            showTitle={false}
            {...restProps}
            showLabel={false}
            onClick={isDisabled ? null : onClick}
            onMouseDown={isDisabled ? null : onMouseDown}
            onMouseUp={isDisabled ? null : onMouseUp}
            sx={{
                ...variantStyles,
                ...(sx || {})
            }}
        />
    );
});

IconButton.displayName = 'IconButton';

IconButton.propTypes = {
    /**
     * Icon which should be used
     */
    icon: PropTypes.elementType.isRequired,
    /**
     * Link target
     */
    href: PropTypes.string,
    /**
     * `pill` set to `true` will change the button's border radius to 100%
     */
    pill: PropTypes.bool,
    /**
     * If `true`, the button will be disabled.
     */
    isDisabled: PropTypes.bool,
    /**
     * color is used for the icon color in the `plain` and `ghost` variants
     */
    color: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string)
    ]),
    /**
     * Action
     */
    onClick: PropTypes.func,
    /**
     * Action
     */
    onMouseDown: PropTypes.func,
    /**
     * Action
     */
    onMouseUp: PropTypes.func,
    /**
     * The size of the button.
     */
    size: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.oneOf(['xs', 'xs+', 'sm', 'sm+', 'md', 'md+', 'lg', 'lg+'])),
        PropTypes.oneOf(['xs', 'xs+', 'sm', 'sm+', 'md', 'md+', 'lg', 'lg+'])
    ]),
    /**
     * Button Variant
     */
    variant: PropTypes.oneOf(['transparent', 'ghost', 'plain'])
};

IconButton.defaultProps = {
    variant: 'plain',
    color: 'whisper',
    pill: true,
    size: 'sm+'
};

export default IconButton;
