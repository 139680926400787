import React from 'react';

// Utils
import PropTypes from 'prop-types';
import {useThemeUI} from 'theme-ui';
import {useGetImageBySrc} from '../../hooks';

// Images
import faviconIco from '../../../images/favicon.ico';
import faviconSvg from '../../../images/favicon.svg';
import faviconPng from '../../../images/favicon-512.png';
import appleTouchIconPng from '../../../images/apple-touch-icon-180.png';

// Components
import Helmet from 'react-helmet';

// Constants
const ORIGIN = 'https://evanvosberg.urban.to';

const MdxHead = ({meta}) => {
    const {theme} = useThemeUI();
    const getImageBySrc = useGetImageBySrc();
    const keyImage = getImageBySrc(meta.keyImage);

    const title = `${meta.title} | Evan Vosberg`;
    const description = meta.description.substr(0, 200);
    const image = keyImage?.images?.fallback?.src ? `${ORIGIN}${keyImage.images.fallback.src}` : null;
    const {tags = [], createdAt, changedAt, canonical, prev, next} = meta;
    const type = createdAt ? 'article' : 'website';

    return (
        <Helmet
            htmlAttributes={{
                lang: meta.lang || 'de'
            }}
        >
            {/* Tech */}
            <meta charset="UTF-8"/>
            <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"/>
            <meta name="robots" content="max-image-preview:large"/>
            {/* Content */}
            <title>
                {title}
            </title>
            <meta name="description" content={description}/>
            {/* Icon & theme */}
            <link className="favicon" rel="icon" href={faviconIco} sizes="any"/>
            <link className="favicon" rel="icon" href={faviconPng} type="image/png"/>
            <link className="favicon" rel="icon" href={faviconSvg} type="image/svg+xml"/>
            <link rel="apple-touch-icon" href={appleTouchIconPng}/>
            <meta name="theme-color" content={theme.rawColors.header}/>
            {/* OpenGraph */}
            <meta property="og:locale" content="de_DE"/>
            <meta property="og:site_name" content="Evan Vosberg"/>
            <meta property="og:type" content={type}/>
            {canonical ? (
                <meta property="og:url" content={`${ORIGIN}${canonical}`}/>
            ) : null}
            <meta property="og:title" content={title}/>
            <meta property="og:description" content={description}/>
            {image ? (
                <meta property="og:image" content={image}/>
            ) : null}
            {/* OpenGraph:Article */}
            {createdAt ? (
                <meta property="article:published_time" content={createdAt}/>
            ) : null}
            {changedAt ? (
                <meta property="article:modified_time" content={changedAt}/>
            ) : null}
            {Array.isArray(tags) ? tags.map(tag => (
                <meta property="article:tag" content={tag} key={tag}/>
            )) : null}
            {/* DISABLED: <meta property="article:section" content="Fahrrad"/> */}
            {/* Twitter */}
            <meta name="twitter:site" content="@evanvosberg"/>
            <meta name="twitter:title" content={title}/>
            <meta name="twitter:description" content={description}/>
            {image ? (
                <meta property="twitter:image" content={image}/>
            ) : null}
            {/* Google / Schema.org */}
            <meta itemprop="name" content={title}/>
            <meta itemprop="headline" content={title}/>
            <meta itemprop="description" content={description}/>
            {image ? (
                <meta itemprop="image" content={image}/>
            ) : null}
            <meta itemprop="author" content="Evan Vosberg"/>
            {createdAt ? (
                <meta itemprop="datePublished" content={createdAt}/>
            ) : null}
            {changedAt ? (
                <meta itemprop="dateModified" content={changedAt}/>
            ) : null}
            {/* Canonical & pagination */}
            {canonical ? (
                <link rel="canonical" href={`${ORIGIN}${canonical}`}/>
            ) : null}
            {prev ? (
                <link rel="prev" href={`${ORIGIN}${prev}`}/>
            ) : null}
            {next ? (
                <link rel="next" href={`${ORIGIN}${next}`}/>
            ) : null}
        </Helmet>
    );
};

MdxHead.propTypes = {
    meta: PropTypes.shape({
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        keyImage: PropTypes.string,
        date: PropTypes.string,
        createdAt: PropTypes.string,
        changedAt: PropTypes.string,
        tags: PropTypes.arrayOf(PropTypes.string)
    })
};

export default MdxHead;
