import React from 'react';
import PropTypes from 'prop-types';
import Box from '../Box';

export const AccordionPanel = ({children, as, variant}) => {
    return (
        <Box
            as={as}
            p={4}
            color={variant === 'plan' ? 'textOnDark' : null}
            bg={variant === 'plan' ? 'navbar' : 'muted'}
        >
            {children}
        </Box>
    );
};

AccordionPanel.defaultProps = {
    as: 'div'
};

AccordionPanel.propTypes = {
    /* html element used to render the accordion panel */
    as: PropTypes.string.isRequired,
    /* variant of the accordion controls the bg and color of the panel */
    variant: PropTypes.oneOf(['default', 'plan'])
};

export default AccordionPanel;
