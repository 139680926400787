import React from 'react';
import PropTypes from 'prop-types';

import Box from '../Box';

const DropdownMenuItem = React.forwardRef((props, ref) => {
    const {as, onClick, icon: Icon, href, children} = props;

    return (
        <Box
            ref={ref}
            as={as || href ? 'a' : 'button'}
            href={href}
            onClick={onClick}
            sx={{
                appearance: 'none',
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                cursor: 'pointer',
                textAlign: 'left',
                bg: 'card',
                fontSize: 1,
                p: 2,
                color: 'text',
                ':hover, :focus': {
                    bg: 'primary',
                    color: 'textOnPrimary'
                },
                '.dropdown-menu-icon': {
                    color: 'whisper'
                },
                ':hover .dropdown-menu-icon, :focus .dropdown-menu-icon': {
                    color: 'textOnPrimary'
                }
            }}
            data-test-id={props['data-test-id']}
        >
            {Icon && (
                <Box as="span" className="dropdown-menu-icon" sx={{pr: 2}}>
                    <Icon aria-hidden="true"/>
                </Box>
            )}
            {children}
        </Box>
    );
});

DropdownMenuItem.displayName = 'DropdownMenuItem';

DropdownMenuItem.propTypes = {
    /**
     * when nothing is specified this will default to either a button or a
     * element, depending if the href attribute is set
     */
    as: PropTypes.string,
    /**
     * pass an icon component to display next to the menu item
     */
    icon: PropTypes.elementType,
    /**
     * using `href` will render the `<DropdownMenuItem/>` as an `<a>` Element
     */
    href: PropTypes.string,
    onClick: PropTypes.func
};

DropdownMenuItem.defaultProps = {};

export default DropdownMenuItem;
