const getPropsByHref = (href) => {
    let target = null;
    let rel = null;

    try {
        const url = new URL(href);

        const hostname = typeof window === 'object' ? window.location.hostname : 'evanvosberg.urban.to';

        if (url.hostname !== hostname) {
            target = '_black';
            rel = 'noopener noreferrer';
        }

    } catch {}

    return {rel, target};
};

export default getPropsByHref;
