import React from 'react'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';

import Box from '../Box';
import Label from '../Label';
import {FlexCol} from '../Flex';

import {IconDropdown} from '../../icons';

/**
 * Before using a select menu, consider using a set of radio buttons instead.
 * This will work way better in most scenarios. Should only be considered if
 * you have a lot of options to show or very limited space.
*/
const Select = React.forwardRef((props, ref) => {
    const {id, children, label, bold, ...restProps} = props;

    return (
        <FlexCol space="sm">
            {label && (
                <Label htmlFor={id} bold={bold}>
                    {label}
                </Label>
            )}
            <Box
                display="flex"
            >
                <Box
                    ref={ref}
                    id={id}
                    as="select"
                    sx={{
                        display: 'block',
                        width: '100%',
                        px: 2,
                        appearance: 'none',
                        fontSize: 'inherit',
                        lineHeight: 'inherit',
                        minHeight: 10,
                        border: '1px solid',
                        borderColor: 'border',
                        borderRadius: 'md',
                        color: 'inherit',
                        bg: 'transparent'
                    }}
                    {...restProps}
                >
                    {children}
                </Box>
                <IconDropdown sx={{
                    color: 'whisper',
                    alignSelf: 'center',
                    pointerEvents: 'none',
                    ml: -8,
                    ':hover': {
                        color: 'primary'
                    }
                }}
                />
            </Box>
        </FlexCol>
    );
}
);

Select.displayName = 'Select';

Select.propTypes = {
    /**
     * unique id for the select. when a label is provided this will automatically
     * be used as the `for` attribute
     */
    id: PropTypes.string.isRequired,
    /**
     * Label for the select. Make sure to skip this only when you are providing
     * an alternative label (and have a good reason to break our design
     * conventions around labels on top of inputs/textareas)
     */
    label: PropTypes.node,
    /**
     * this controls wether the label should be bold or not
     */
    bold: PropTypes.bool
};

Select.defaultProps = {
    bold: true
};

export default Select;
