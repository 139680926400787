import React, {Children, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {FlexCol} from '../Flex';

export const Accordion = ({allowMultiOpen, children, as, variant}) => {
    const DEFAULT_IS_OPEN_STATE = Children.toArray(children).map(child => Boolean(child.props.defaultIsOpen));
    const [isOpen, setIsOpen] = useState(DEFAULT_IS_OPEN_STATE);

    useEffect(() => {
        setIsOpen(Children.toArray(children).map(child => Boolean(child.props.defaultIsOpen)));
    }, [children.length]);

    const handleClick = (e, i) => {
        e.preventDefault();
        setIsOpen(isOpen.reduce((acc, cur, curI) => {
            return [
                ...acc,
                curI === i ? !isOpen[i] : (allowMultiOpen ? cur : false)
            ];
        }, []));
    };

    return (
        <FlexCol as={as} space="none" dividers={variant==='default' ? true : false}>
            {Children.toArray(children).filter(Boolean).map((child, i) =>
                React.cloneElement(child, {
                    isOpen: child.props.isOpen ?? isOpen[i],
                    onClick: child.props.onClick ?? ((e) => handleClick(e, i)),
                    variant: child.props.variant ?? variant
                })
            )}
        </FlexCol>
    );
};

Accordion.propTypes = {
    /* allows multiple items to be open, doesn't close automatically */
    allowMultiOpen: PropTypes.bool,
    /* variant of the accordion controls the bg and color of the item and panel */
    variant: PropTypes.oneOf(['default', 'plan']),
    children: PropTypes.node
};

Accordion.defaultProps = {
    allowMultiOpen: false,
    variant: 'default'
};

export default Accordion;
