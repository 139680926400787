import React, {useContext} from 'react'; // eslint-disable-line no-unused-vars
import {useThemeUI} from 'theme-ui';
import PropTypes from 'prop-types';
import Box from '../Box';
import Text from '../Text';

import {BulletListContext} from '../BulletList';
import {IconSelected} from '../../icons';

/**
 * Use this component for Bullets, use this standalone or together with
 * `BulletList` which provides context to change all colors, icons, etc. for
 * all children
 */
const Bullet = React.forwardRef(({
    as,
    children,
    color,
    size,
    variant,
    ...restProps
}, ref) => {
    const {theme} = useThemeUI();
    const {bulletSize, bulletColor, bulletVariant} =
    useContext(BulletListContext) || false;

    const calculatedLineHeight = Array.isArray(bulletSize || size)
        ? (bulletSize || size).map(size => `${theme.fontSizes[size] * 1.5}px`)
        : `${theme.fontSizes[bulletSize || size] * 1.5}px`;

    return (
        <Box ref={ref} as={as} display="flex" {...restProps}>
            <Box display="flex" alignY="center" height={calculatedLineHeight}>
                {bulletVariant === 'checkmark' || variant === 'checkmark' ? (
                    <IconSelected
                        size={calculatedLineHeight}
                        color={bulletColor || color}
                        sx={{flexShrink: 0}}
                    />
                ) : (
                    <Box
                        sx={{
                            flexShrink: 0,
                            borderRadius: 'full',
                            width: '0.4em',
                            height: '0.4em',
                            bg: bulletColor || color
                        }}
                    />
                )}
            </Box>
            <Text as="span" size={bulletSize || size} ml={3}>
                {children}
            </Text>
        </Box>
    );
});

Bullet.displayName = 'Bullet';

Bullet.propTypes = {
    /** element placed in front of the text */
    variant: PropTypes.oneOf(['dot', 'checkmark']),
    /** dot color  */
    color: PropTypes.string,
    /** font size size */
    size: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])), PropTypes.number, PropTypes.string])
};

Bullet.defaultProps = {
    as: 'li',
    variant: 'dot',
    color: 'whisper',
    size: 2
};

export default Bullet;
