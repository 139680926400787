import React from 'react';
import PropTypes from 'prop-types';
import Box from '../Box';

/**
 * Element to constrain content.
 */
const Container = React.forwardRef(({maxWidth, children, center, ...domProps}, ref) => {
    return (
        <Box
            ref={ref}
            mx={center === true ? 'auto' : null}
            maxWidth={maxWidth}
            {...domProps}
        >
            {children}
        </Box>
    );
});

Container.displayName = 'Container';

Container.propTypes = {
    center: PropTypes.bool,
    maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
};

Container.defaultProps = {
    center: true,
    maxWidth: '8xl'
};

export default Container;