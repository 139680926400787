import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

import {css, Global, ClassNames} from '@emotion/react';
import {useThemeUI} from 'theme-ui';

import Container from '../Container';
import Box from '../Box';
import Card from '../Card';
import Divider from '../Divider';
import Heading from '../Heading';
import {FlexCol} from '../Flex';
import {FlexRow} from '../Flex';
import IconButton from '../IconButton';

import {IconClose} from '../../icons';

/**
 * Dialog
 */
const Dialog = React.forwardRef(({
    title,
    footer,
    size,
    children,
    // Modal props
    isOpen,
    contentLabel = title,
    shouldCloseOnX = false,
    shouldCloseOnEsc = true,
    // TODO: get this prop to work
    // shouldCloseOnOverlayClick = true,
    onRequestClose,
    ...restProps
}, ref) => {
    const context = useThemeUI();
    const {theme} = context;
    const isBrowser = typeof window !== 'undefined';

    Modal.setAppElement('#pageMountNode');

    const scrollBarWidth = isBrowser
        ? window.innerWidth - document.documentElement.clientWidth
        : 0;

    const dialogMaxWidth = {
        sm: 'md',
        md: 'xl',
        lg: '3xl'
    };

    const styleStrings = {
        modalBody: `
            width: 100%;
            height: 100%;
            :focus {
                outline: 0;
                box-shadow: none;
            }
        `,
        overlay: `
            z-index: ${theme.zIndices['6']};
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            overflow: auto;
            justify-content: center;
            align-items: center;
            opacity: 0;
            transition: opacity .2s ease-in-out;
            background-color: rgba(0, 0, 0, 0.6);
        `,
        overlayAfterOpen: `
            opacity: 1;
        `,
        overlayBeforeClose: `
            opacity: 0;
        `
    };
    return (
        <>
            <Global
                styles={css`
                    .ReactModal__Body--open {
                        overflow: hidden;
                        margin-right: ${scrollBarWidth.current}px;
                    }
                `}
            />
            <ClassNames>
                {({css, cx}) => (
                    <Modal
                        ref={ref}
                        className={{
                            base: cx(css` ${styleStrings.modalBody}; `),
                            afterOpen: '',
                            beforeClose: ''
                        }}
                        overlayClassName={{
                            base: 'js-scrollable ' + css` ${styleStrings.overlay}; `,
                            afterOpen: css` ${styleStrings.overlayAfterOpen}; `,
                            beforeClose: css` ${styleStrings.overlayBeforeClose}; `
                        }}
                        isOpen={isOpen}
                        contentLabel={contentLabel}
                        shouldCloseOnEsc={shouldCloseOnEsc}
                        // TODO: get this prop to work
                        // shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
                        onRequestClose={onRequestClose}
                    >
                        <Box p={['sm', 'md', 'lg']}>
                            <Container maxWidth={dialogMaxWidth[size]}>
                                <Card elevation={3} {...restProps}>
                                    <Box p={[3, 3, 6]}>
                                        <FlexCol>
                                            {title && shouldCloseOnX
                                                ? (
                                                    <FlexCol space="sm">
                                                        <FlexRow reverse={true} alignY="center">
                                                            <FlexRow width="content">
                                                                <IconButton
                                                                    icon={IconClose}
                                                                    variant="ghost"
                                                                    onClick={onRequestClose}
                                                                />
                                                            </FlexRow>
                                                            <FlexRow>
                                                                <Heading level={5}>
                                                                    {title}
                                                                </Heading>
                                                            </FlexRow>
                                                        </FlexRow>
                                                        <Divider/>
                                                    </FlexCol>
                                                )
                                                : title
                                                    ? (
                                                        <FlexCol space="sm">
                                                            <Heading level={5}>
                                                                {title}
                                                            </Heading>
                                                            <Divider/>
                                                        </FlexCol>
                                                    )
                                                    : shouldCloseOnX
                                                        ? (
                                                            <FlexCol space="sm">
                                                                <FlexRow reverse={true} alignY="center">
                                                                    <FlexRow width="content">
                                                                        <IconButton
                                                                            icon={IconClose}
                                                                            variant="ghost"
                                                                            onClick={onRequestClose}
                                                                        />
                                                                    </FlexRow>
                                                                </FlexRow>
                                                                <Divider/>
                                                            </FlexCol>
                                                        )
                                                        : null}
                                            {children}
                                            {footer && (
                                                <FlexRow alignX={['center', 'left']} reverse={true}>
                                                    {footer}
                                                </FlexRow>
                                            )}
                                        </FlexCol>
                                    </Box>
                                </Card>
                            </Container>
                        </Box>
                    </Modal>
                )}
            </ClassNames>
        </>
    );
});

Dialog.displayName = 'Dialog';

Dialog.propTypes = {
    /** Whether to show the dialog */
    isOpen: PropTypes.bool,
    /** Dialog content */
    children: PropTypes.node,
    /** Title of the dialog */
    title: PropTypes.string,
    /** Description of the content for accessibility */
    contentLabel: PropTypes.string,
    /** Maximum width of the dialog */
    size: PropTypes.oneOf(['sm', 'md', 'lg']),
    /** Dialog footer for actions */
    footer: PropTypes.node
};

Dialog.defaultProps = {
    size: 'md'
};

export default Dialog;
