import React from 'react';

import {ThemeProvider} from 'theme-ui';
import theme from '../../theme';

const UIThemeProvider = ({children}) => {
    return (
        <ThemeProvider theme={theme}>
            {children}
        </ThemeProvider>
    );
};

export default UIThemeProvider;
