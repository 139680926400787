

import React from 'react';

const createComponent = (displayName, Component, props) => {
    const MDXComponent = ({children, ...restProps}) => {
        const componentProps = typeof props === 'function'
            ? props(restProps)
            : props;

        return (
            <Component {...componentProps}>
                {children}
            </Component>
        );
    };
    MDXComponent.displayName = `createComponent(${displayName})`;

    return MDXComponent;
};

export default createComponent;
