import React from 'react';

// Utils
import {lighten} from '@theme-ui/color';
import {usePageContext} from '../../contexts';

// Components
import {Badge, Box, Flex, FlexCol, FlexInline, Grid, Text, TextLink} from '../../../ui/components';

const ALIGN_X = ['center', 'center', 'left'];

const MdxSubFooter = () => {
    const {tags, featuredArticles, recentArticles} = usePageContext();

    const gridItems = [{
        title: 'Neueste Artikel',
        articleItems: recentArticles.items
    }, {
        title: 'Featured Artikel',
        articleItems: featuredArticles.items
    }].filter(gridItem => gridItem.articleItems.length);

    return (
        <Flex
            variant={['column', 'column', 'row']}
            width="100%"
            maxWidth="6xl"
            minHeight="xxs"
            alignX="stretch"
            alignY="top"
            space={['md', 'md', 'lg']}
            py={['sm', 'md']}
        >
            <Grid
                width="100%"
                columns={[1, 1, 2, 2, 3]}
                space="lg"
                wrapChildren={true}
            >
                {gridItems.map((gridItem, i) => (
                    <FlexCol
                        key={`${gridItem.title}`}
                        space="sm"
                        alignX={ALIGN_X}
                        gridItem={`grid-item-${i}`}
                    >
                        <Text
                            bold={true}
                            color="textOnFooter"
                            align={ALIGN_X}
                        >
                            {gridItem.title}
                        </Text>
                        <FlexCol
                            as="ul"
                            space="sm"
                            alignX={ALIGN_X}
                        >
                            {gridItem.articleItems.map(article => (
                                <Box
                                    as="li"
                                    display="flex"
                                    key={`li_${article.page.key}`}
                                    alignX={ALIGN_X}
                                >
                                    <TextLink
                                        href={article.page.pathname}
                                        color="textOnFooter"
                                        decoration="inverted"
                                        sx={{textAlign: ALIGN_X}}
                                    >
                                        {article.meta.title}
                                    </TextLink>
                                </Box>
                            ))}
                        </FlexCol>
                    </FlexCol>
                ))}
                {tags.length > 1 ? (
                    <FlexCol
                        space="sm"
                        alignX={ALIGN_X}
                        gridItem={`grid-item-${gridItems.length}`}
                    >
                        <Text
                            bold={true}
                            color="textOnFooter"
                            align={ALIGN_X}
                        >
                            {'Kategorien'}
                        </Text>
                        <FlexCol
                            as="ul"
                            space="sm"
                            alignX={ALIGN_X}
                        >
                            <FlexInline as="li" space="xs" alignX={ALIGN_X}>
                                {tags.map(tag => (
                                    <Badge
                                        key={tag.page.key}
                                        as="a"
                                        href={tag.page.pathname}
                                        bg="almostBlack"
                                        color="lighterGrey"
                                        sx={{
                                            ':hover': {
                                                cursor: 'pointer',
                                                bg: lighten('almostBlack', 0.1)
                                            }
                                        }}
                                    >
                                        {tag.meta.title}
                                    </Badge>
                                ))}
                            </FlexInline>
                        </FlexCol>
                    </FlexCol>
                ) : null}
            </Grid>
        </Flex>
    );
};

export default MdxSubFooter;
