import React from 'react';
import PropTypes from 'prop-types';
import Tippy from '@tippyjs/react/headless';
import Box from '../Box';

/**
 * Tooltip
 */
const Tooltip = ({
    arrow,
    children,
    content,
    disabled,
    maxWidth,
    offset,
    placement,
    trigger,
    visible,
    hideOnClick,
    ...tooltipProps
}) => {
    // some properties only apply if `visible` isn't controlled
    const controlProps = visible === undefined ? {trigger, hideOnClick} : {visible, trigger: undefined};

    return (
        <Tippy
            arrow={true}
            disabled={Array.isArray(disabled) ? undefined : disabled}
            placement={placement}
            offset={offset}
            {...tooltipProps}
            {...controlProps}
            render={attrs => (
                <Box tabIndex="-1" {...attrs} sx={{
                    display: Array.isArray(disabled) ? disabled.map(disabled => disabled ? 'none' : 'inherit') : disabled ? 'none' : 'inherit',
                    bg: 'tooltip',
                    maxWidth: maxWidth,
                    color: 'textOnDark',
                    py: 1,
                    px: 2,
                    fontSize: 1,
                    borderRadius: 'md',
                    '&[data-placement^=\'top\'] > .tippy-tooltip-arrow': {bottom: '-4px'},
                    '&[data-placement^=\'bottom\'] > .tippy-tooltip-arrow': {top: '-4px'},
                    '&[data-placement^=\'left\'] > .tippy-tooltip-arrow': {right: '-4px'},
                    '&[data-placement^=\'right\'] > .tippy-tooltip-arrow': {left: '-4px'}
                }}
                >
                    {content}
                    {arrow === true && (
                        <Box className="tippy-tooltip-arrow" data-popper-arrow="" sx={{
                            position: 'absolute',
                            width: '8px',
                            height: '8px',
                            zIndex: -1,
                            ':before': {
                                content: '""',
                                transform: 'rotate(45deg)',
                                bg: 'tooltip',
                                position: 'absolute',
                                width: '8px',
                                height: '8px',
                                zIndex: -1
                            }
                        }}
                        />

                    )}
                </Box>
            )}
        >
            {typeof children === 'string' ? (
                <Box as="span" tabIndex="0" sx={{display: 'inline-block', ':focus': {outline: 0, boxShadow: 'none'}}}>
                    {children}
                </Box>
            ) : children}
        </Tippy>
    );
};

Tooltip.displayName = 'Tooltip';

Tooltip.propTypes = {
    /** Enables Arrow for Tooltip */
    arrow: PropTypes.bool,
    /** Tooltip element */
    children: PropTypes.node,
    /** Tooltip content */
    content: PropTypes.node,
    /** prevent tooltip from showing */
    disabled: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.bool),
        PropTypes.bool
    ]),
    /** Maximum width of the Tooltip */
    maxWidth: PropTypes.number,
    /**
     * Determines if the Tooltip hides upon clicking the reference or outside of
     * the Tooltip. The behavior can depend upon the trigger events used.
     * see: https://atomiks.github.io/tippyjs/v6/all-props/#hideonclick
     */
    hideOnClick: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    /**
     * Determines the events that cause the tooltip to show. Multiple event
     * names are separated by spaces. See
     * https://atomiks.github.io/tippyjs/v6/all-props/#trigger for more info
     */
    trigger: PropTypes.string,
    /**
     * The *preferred* placement of the tooltip. This might flip, depending on
     * the available space. The `auto` placements will choose the side with most space. Also see
     * https://atomiks.github.io/tippyjs/v6/all-props/#placement
     */
    placement: PropTypes.oneOf(['top', 'right', 'bottom', 'left', 'auto']),
    /**
     * Determines the behavior on touch devices. See:
     * https://atomiks.github.io/tippyjs/v6/all-props/#touch
     */
    touch: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.bool]),
    /**
     * Displaces the tooltip from its reference element in pixels (skidding and distance). See https://atomiks.github.io/tippyjs/v6/all-props/#offset for more details.
     */
    offset: PropTypes.array,
    /**
     * Invoked when the user clicks anywhere outside of the tooltip element. Use
     * this together with `visible` if you need to control the tooltip from outside.
     */
    onClickOutside: PropTypes.func,
    /** Use React's state to fully control the tooltip instead of relying on the `trigger` and `hideOnClick` props */
    visible: PropTypes.bool
};

Tooltip.defaultProps = {
    arrow: true,
    trigger: 'mouseenter focus',
    offset: [0, 12],
    hideOnClick: true,
    placement: 'top',
    touch: true,
    maxWidth: 64,
    zIndex: 500
};

export default Tooltip;
