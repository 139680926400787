// Images
import preHeaderBgSvg from '../../images/pre-header-bg.svg';

const usePreHeaderBgSx = (src) => {
    return {
        backgroundPosition: 'center bottom',
        backgroundRepeat: 'repeat no-repeat',
        backgroundImage: [
            `url(${preHeaderBgSvg})`
        ]
    };
};

export default usePreHeaderBgSx;
