import React, {useContext} from 'react';
import {lighten, shade} from '@theme-ui/color';
import PropTypes from 'prop-types';
import Box from '../Box';

import {TabListContext} from '../TabList';

/**
 * Tab renders a single tab item. It should be placed inside `TabList` which controls the layouting.
 */
const Tab = React.forwardRef(
    ({as, bg, label, isDisabled, isActive, variant, onClick, ...restProps}, ref) => {
        const {TabListBg} = useContext(TabListContext) || false;

        const style = variant === 'underline' ? {
            p: 3,
            whiteSpace: 'nowrap',
            display: 'block',
            textAlign: 'center',
            width: '100%',
            borderBottomWidth: 2,
            borderBottomColor: isActive ? 'primary' : 'transparent',
            cursor: isDisabled ? 'not-allowed' : 'pointer',
            color: isDisabled ? 'disabled' : isActive ? 'primary' : 'text',
            fontWeight: isActive ? 'bold' : null,
            '&:focus': isDisabled
                ? null
                : {
                    borderBottomColor: lighten('primary', 0.1)
                },
            '&:hover': isDisabled
                ? null
                : {
                    borderBottomColor: lighten('primary', 0.1)
                }
        } : {
            borderTopLeftRadius: 'md',
            borderTopRightRadius: 'md',
            borderWidth: 1,
            borderColor: isActive ? 'border' : 'transparent',
            borderBottomColor: isActive ? TabListBg || bg : 'border',
            bg: isActive ? (TabListBg || bg) : 'transparent',
            display: 'block',
            cursor: isDisabled ? 'not-allowed' : 'pointer',
            textAlign: 'center',
            width: '100%',
            whiteSpace: 'nowrap',
            p: 3,
            color: isDisabled ? 'disabled' : isActive ? 'primary' : 'text',
            fontWeight: isActive ? 'bold' : null,
            '&:hover, &:focus': isDisabled
                ? null
                : {
                    borderColor: 'border',
                    borderColor: shade('border', 0.1),
                    borderBottomColor: isActive ? TabListBg || bg : 'border'
                }
        };

        return (
            <Box
                as={as}
                ref={ref}
                onClick={isDisabled ? undefined : onClick}
                {...restProps}
                sx={style}
            >
                {label}
            </Box>
        );
    }
);

Tab.displayName = 'Tab';

Tab.propTypes = {
    /**
   * html element used to render the tab
   */
    as: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
    /**
   * background color of the tab, as well as the `borderColor` of when
   * `isActive={true}`. This is also provided via react context in `TabList`
   */
    bg: PropTypes.string,
    /**
   * controls wether the tab is clickable/selectable
   */
    isDisabled: PropTypes.bool,
    /**
   *  text label of the tab
   */
    label: PropTypes.string,
    /**
   *  controls wether the tab is currently active
   */
    isActive: PropTypes.bool
};

Tab.defaultProps = {
    as: 'button',
    isActive: false,
    isDisabled: false
};

export default Tab;
