

import {useContext} from 'react';

import {PageContext} from '../contexts';

const useGetImageBySrc = (src) => {
    const {imagesBySrc} = useContext(PageContext);

    return (src) => {
        return imagesBySrc?.[src] || null;
    };
};

export default useGetImageBySrc;
