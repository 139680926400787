import React from 'react';
import PropTypes from 'prop-types';
import {useThemeUI} from 'theme-ui';
import {alpha, shade} from '@theme-ui/color';

import Box from '../Box';
import CoreButton from '../CoreButton';

/**
 * `Toggle` is a styled checkbox / radio button.
 *
 * This is handled by using html's `<input>` element and has two different
 * types: `radio` or `checkbox`. Here's a short explanation when it's
 * appropriate to use them:
 *
 * > Radio buttons are similar to checkboxes, but with an important distinction — within a group of actions only one radio button can be selected at a time, whereas checkboxes allow for multiple values to be selected. That's also reflected in the different behaviour when navigating with a keyboard.
 *
 * Should be used with a label most of the times, if you don't have enough space you can control the label's display properties across breakpoints with `showLabel`. When you don't use a label, consider using a tooltip to provide some guidance for users.
 *
 */
const Toggle = React.forwardRef(({
    type,
    name,
    value,
    defaultChecked,
    checked,
    size,
    icon: Icon,
    isDisabled,
    color,
    selectedColor,
    label,
    showLabel,
    title,
    onChange,
    onClick,
    ...restProps
}, ref) => {
    const {theme} = useThemeUI();

    color = isDisabled
        ? [theme.rawColors.disabled]
        : Array.isArray(color)
            ? color.map(color => theme.rawColors[color])
            : color
                ? [theme.rawColors[color]]
                : [];

    selectedColor = Array.isArray(selectedColor)
        ? selectedColor.map(color => theme.rawColors[selectedColor])
        : selectedColor
            ? [theme.rawColors[selectedColor]]
            : [];

    const variantStyles = {
        color: color,
        fontWeight: 'bold',
        'input:hover ~ &, input:focus ~ &': isDisabled
            ? null
            : {
                color: color.map(color => shade(color, 0.15)),
                bg: color.map(color => alpha(color, 0.1))
            },
        'input:checked ~ &': isDisabled
            ? null
            : {
                color: selectedColor.map(selectedColor => selectedColor),
                bg: selectedColor.map(selectedColor => alpha(selectedColor, 0.1))
            },
        'input:focus ~ &': isDisabled
            ? null
            : {
                outline: 0,
                bg: 'rgba(66, 153, 225, 0.1)',
                borderColor: '#63b3ed !important',
                boxShadow: '0 0 0 3px rgba(66, 153, 225, 0.5)'
            }
    };

    return (
        <Box
            as="label"
            sx={{
                position: 'relative',
                display: 'inline-flex'
            }}
        >
            <Box
                ref={ref}
                as="input"
                type={type}
                name={name}
                value={value}
                defaultChecked={defaultChecked}
                checked={checked}
                disabled={isDisabled || void 0}
                onChange={onChange}
                onClick={onClick}
                sx={{
                    position: 'absolute',
                    opacity: 0,
                    margin: 0,
                    width: 0,
                    height: 0,
                    overflow: 'hidden'
                }}
            />
            <CoreButton
                as="span"
                size={size}
                iconLeft={Icon}
                label={label}
                showLabel={showLabel}
                title={title}
                showTitle={Array.isArray(showLabel) ? showLabel.map(showLabel => !showLabel) : !showLabel}
                isDisabled={isDisabled}
                {...restProps}
                sx={{
                    ...variantStyles
                }}
            />
        </Box>
    );
});

Toggle.displayName = 'Toggle';

Toggle.propTypes = {
    /** optional title for tooltips */
    title: PropTypes.string,
    /** toggle can either work as a checkbox or radio input */
    type: PropTypes.oneOf(['checkbox', 'radio']),
    /**
     * The size of the toggle button.
     */
    size: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.oneOf(['xs', 'xs+', 'sm', 'sm+', 'md', 'md+', 'lg', 'lg+'])),
        PropTypes.oneOf(['xs', 'xs+', 'sm', 'sm+', 'md', 'md+', 'lg', 'lg+'])
    ]),
    /** pass icon component which should be used */
    icon: PropTypes.elementType.isRequired,
    /** If `true`, the button will be disabled. */
    isDisabled: PropTypes.bool,
    /** optional: label */
    label: PropTypes.string,
    /** optional: controls the label's display properties across breakpoints */
    showLabel: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.arrayOf(PropTypes.bool)
    ]),
    /** color for the initial state */
    color: PropTypes.string,
    /** color for the selected state */
    selectedColor: PropTypes.string
};

Toggle.defaultProps = {
    size: 'md+',
    color: 'whisper',
    selectedColor: 'primary',
    type: 'checkbox',
    showLabel: false
};

export default Toggle;
