const UML = {
    'ä': 'a',
    'ö': 'o',
    'ü': 'u',
    'ß': 'ss'
};

const toSlug = (string) => {
    return string
        .toLowerCase()
        .replace(/([äöüß])/g, ($0, $1) => UML[$1])
        .replace(/[^a-z0-9\s]/g, '')
        .trim()
        .replace(/\s+/g, '-');
};

const toTagNHref = (tag, page = 0) => {
    const tagSlug = toSlug(tag);

    const paginationSlug = page > 1
        ? `/${page}/`
        : '/';

    return `/tag/${tagSlug}${paginationSlug}`;
};

export default toTagNHref;
