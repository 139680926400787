import React from 'react';
import PropTypes from 'prop-types';

import Box from '../Box';
import LoadingSpinner from '../LoadingSpinner';

/**
 * Switch enables a user to quickly toggle between two states.
 *
 * `<Switch/>` is similar to a radio group in function but is used for quickly toggling between binary actions. Switch must always be accompanied by a label, and follows the same keyboard workflow as a checkbox.
 *
*/
const Switch = React.forwardRef(({type, variant, icon: Icon, isLoading, isDisabled, selectedColor, title, ...restProps}, ref) => {

    return (
        isLoading
            ? (
                <Box sx={{width: 12, height: 6, display: 'inline-flex', verticalAlign: 'bottom', alignItems: 'center', justifyContent: 'center'}}>
                    <LoadingSpinner/>
                </Box>
            )
            : (
                <Box as="label" display="inline-block" title={title}>
                    <Box
                        ref={ref}
                        as="input"
                        type="checkbox"
                        {...restProps}
                        sx={{
                            position: 'absolute',
                            opacity: 0,
                            zIndex: -1,
                            width: 1,
                            height: 1,
                            overflow: 'hidden'
                        }}
                    />
                    <Box aria-hidden="true" sx={{
                        display: 'inline-flex',
                        verticalAlign: 'bottom',
                        alignItems: 'center',
                        cursor: isDisabled ? 'not-allowed' : 'pointer',
                        justifyContent: 'flex-start',
                        bg: isDisabled ? 'rgba(0,0,0,0.05)' : 'rgba(0,0,0,0.2)',
                        borderRadius: '9999em',
                        width: 12,

                        'input:focus ~ &': isDisabled ? null : {
                            outline: 0,
                            boxShadow: '0 0 0 3px rgba(66, 153, 225, 0.5)'
                        },

                        'input:checked ~ &': isDisabled ? null : {
                            bg: selectedColor
                        },
                        'input:checked ~ & > .switch-knob': isDisabled ? null : {
                            transform: 'translateX(100%)'
                        }
                    }}
                    >
                        <Box className="switch-knob" sx={{
                            width: 6,
                            height: 6,
                            bg: 'white',
                            borderRadius: '9999em',
                            boxShadow: '0 0 1px rgba(0, 0, 0, 0.33), 0 2px 4px rgba(0, 0, 0, 0.22)',
                            transition: 'transform .1s ease-in-out'
                        }}
                        />
                    </Box>
                </Box>
            )
    );
});

Switch.displayName = 'Switch';

Switch.propTypes = {
    /** disabled state for the switch renders it with a slightly less visible bg and updates the cursor */
    isDisabled: PropTypes.bool,
    /** background color when the switch is enabled */
    selectedColor: PropTypes.string,
    /** loading state for the switch renders a `<LoadingSpinner/>` instead, but keeps the initial width and height of the switch */
    isLoading: PropTypes.bool
};

Switch.defaultProps = {
    selectedColor: 'primary'
};

export default Switch ;