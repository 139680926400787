import React from 'react';
import PropTypes from 'prop-types';

import Box from '../Box';
import Button from '../Button';
import {FlexRow} from '../Flex';

import {IconInfo, IconWarning, IconError, IconSuccess} from '../../icons';

/**
 * `<Toast/>` provides brief messages about actions / processes at the bottom of the
 * screen. Only appears temporarily, towards the bottom of the screen and shouldn’t
 * interrupt the user experience.
 *
 * Toasts don’t require user input to disappear. They automatically
 * disappear from the screen after 4 to 10 seconds.
 *
 * Toasts can display a single text button that lets users take action, e.g. Retry, Cancel, Undo.
 *
 * They are not stacked on top of each other. As soon as a new toast is
 * triggered it will replace the current message
 */

const Toast = React.forwardRef((props, ref) => {
    const {onAction, actionLabel, children, variant, ...restProps} = props;

    const icons = {
        'error': IconError,
        'info': IconInfo,
        'warning': IconWarning,
        'success': IconSuccess
    };

    const iconColors = {
        'error': 'error',
        'info': 'textOnDark',
        'warning': 'warning',
        'success': 'primaryOnDark'
    };

    const ToastIcon = icons[variant];
    const iconColor = iconColors[variant];

    return (
        <Box
            ref={ref}
            sx={{
                p: 'sm',
                borderRadius: 'md',
                color: 'textOnDark',
                bg: 'navbar',
                boxShadow: 'md'
            }}
            {...restProps}
        >
            <FlexRow alignX="center" space="sm">
                <FlexRow width="content">
                    <Box
                        sx={{
                            flexShrink: 'none',
                            color: iconColor
                        }}
                    >
                        <ToastIcon/>
                    </Box>
                </FlexRow>
                <FlexRow>
                    {children}
                </FlexRow>
                {onAction && (
                    <FlexRow width="content">
                        <Button variant="link" color="primaryOnDark" onClick={onAction}>
                            {actionLabel}
                        </Button>
                    </FlexRow>
                )}
            </FlexRow>
        </Box>
    );
});

Toast.displayName = 'Toast';

Toast.propTypes = {
    /** Types of toasts:
     *
     *  - `error` is shown when something goes wrong.
     *  - `success` is shown when something goes right.
     *  - `warning` is shown when extra care should be taken.
     *  - `info` is shown when neutral information is required.
     */
    variant: PropTypes.oneOf(['error', 'info', 'warning', 'success']),
    /** toast message */
    children: PropTypes.node,
    /** delay in seconds after which the toast disappears  */
    delay: PropTypes.number,
    /** optional user action, e.g. to view newly created content or to undo things */
    onAction: PropTypes.func,
    /** label for the action */
    actionLabel: PropTypes.string
};

Toast.defaultProps = {
    variant: 'success'
};

export default Toast;
