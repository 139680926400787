import React from 'react';

// Utils
import {createComponent, getPropsByHref} from '../../utils';

// Components
import {MDXContentContextProvider} from '../../contexts';
import {Box, Text, TextLink} from '../../../ui/components';

const components = {
    p: 'span',
    a: createComponent('a', TextLink, ({href}) => ({
        href,
        decoration: 'inverted',
        ...getPropsByHref(href)
    })),
    em: createComponent('em', Box, {
        as: 'em',
        sx: {
            fontStyle: 'italic'
        }
    }),
    strong: createComponent('strong', Box, {
        as: 'strong',
        sx: {
            fontWeight: 'bold'
        }
    }),
};

const MdxText = ({children, ...props}) => {
    return (
        <Text {...props}>
            <MDXContentContextProvider components={components}>
                {children}
            </MDXContentContextProvider>
        </Text>
    );
};

export default MdxText;
