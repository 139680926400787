import React from 'react'; // eslint-disable-line no-unused-vars
import Box from '../Box';
import PropTypes from 'prop-types';

/**
 * AspectRatio
 */
const AspectRatio = React.forwardRef(({ratio, children, ...restProps}, ref) => {
    const ratioFromProp = (prop) =>
        ({
            square: 100 / (1 / 1) + '%',
            default: 100 / (4 / 3) + '%',
            wide: 100 / (16 / 9) + '%'
        }[prop] || 100 / prop + '%');

    const mapAspectRatio = (ratio) =>
        Array.isArray(ratio) ? ratio.map(ratioFromProp) : ratioFromProp(ratio);

    return (
        <Box
            ref={ref}
            width="100%"
            height="0"
            position="relative"
            sx={{
                pb: mapAspectRatio(ratio)
            }}
        >
            <Box position="absolute" left={0} right={0} top={0} bottom={0} {...restProps}>
                {children}
            </Box>
        </Box>
    );
});

AspectRatio.displayName = 'AspectRatio';

AspectRatio.propTypes = {
    /**
     * responsive prop that accepts 3 different parameter or a number to alter the ratio across screens:
     *
     * - `square`: 1/1
     * - `default`: 4/3
     * - `wide`: 16/9
     */
    ratio: PropTypes.oneOfType([
        PropTypes.arrayOf(
            PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number
            ]),
        ),
        PropTypes.string,
        PropTypes.number
    ])
};

AspectRatio.defaultProps = {
    ratio: 'default'
};

export default AspectRatio;
