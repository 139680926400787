import React, {Fragment} from 'react';

// Utils
import PropTypes from 'prop-types';
import {getPropsByHref} from '../../utils';
import {useThirdPartyConsent} from '../../../hooks';

// Components
import {AspectRatio, Box, Button, Text, TextLink} from '../../../ui/components';

const MdxKomootEmbed = ({aspectRatio, src}) => {
    const [consent, handleConsent] = useThirdPartyConsent('komoot');

    // Generate link source link from embed href (keep share_token)
    const externalQuery = /share_token=[^=&]+/.test(src)
        ? `?${src.replace(/^.*(share_token=[^=&]+).*$/, '$1')}`
        : '';
    const externalHref = `${src.replace(/\/embed.*$/, '')}${externalQuery}`;
    // Embed src
    const embedSrc = src;

    return (
        <AspectRatio
            ratio={consent ? aspectRatio : 4 / 3}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                bg: 'card',
                gap: ['md', 'md', 'lg']
            }}
        >
            {consent ? (
                <Box
                    as="iframe"
                    loading="lazy"
                    src={embedSrc}
                    frameborder="0"
                    marginwidth="0"
                    marginheight="0"
                    scrolling="no"
                    sx={{
                        width: '100%',
                        height: '100%'
                    }}
                />
            ) : (
                <Fragment>
                    <Box
                        as="svg"
                        width="339"
                        height="102"
                        sx={{
                            width: ['180px', '210px', '240px'],
                            maxWidth: '100%',
                            color: 'textOnCard'
                        }}
                        viewBox="0 0 339 102"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M138.308 39.5326H149.417L136.113 54.1707L150.723 70.4179H139.815C139.557 70.4179 128.205 57.218 128.205 57.218V70.4037H119.436V30.5333H128.205V51.9209L138.308 39.5326Z" fill="currentColor"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M149.976 54.8969C149.976 45.9119 157.31 38.6213 166.352 38.6213C175.394 38.6213 182.727 45.9119 182.727 54.8969C182.727 63.882 175.394 71.1726 166.352 71.1726C157.31 71.1726 149.976 63.8963 149.976 54.8969ZM158.588 54.9112C158.588 59.3966 162.061 63.0277 166.352 63.0277C170.643 63.0277 174.116 59.3966 174.116 54.9112C174.116 50.4258 170.643 46.7947 166.352 46.7947C162.061 46.7947 158.588 50.4258 158.588 54.9112Z" fill="currentColor"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M242.388 54.8969C242.388 45.9119 249.722 38.6213 258.763 38.6213C267.805 38.6213 275.139 45.9119 275.139 54.8969C275.139 63.882 267.805 71.1726 258.763 71.1726C249.722 71.1726 242.388 63.8963 242.388 54.8969ZM250.999 54.9112C250.999 59.4251 254.472 63.0846 258.763 63.0846C263.054 63.0846 266.528 59.4251 266.528 54.9112C266.528 50.3973 263.054 46.7378 258.763 46.7378C254.472 46.7378 250.999 50.3973 250.999 54.9112Z" fill="currentColor"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M296.164 38.6213C287.122 38.6213 279.789 45.9119 279.789 54.8969C279.789 63.8963 287.122 71.1726 296.164 71.1726C305.206 71.1726 312.539 63.882 312.539 54.8969C312.539 45.9119 305.206 38.6213 296.164 38.6213ZM296.164 63.0846C291.873 63.0846 288.4 59.4251 288.4 54.9112C288.4 50.3973 291.873 46.7378 296.164 46.7378C300.455 46.7378 303.928 50.3973 303.928 54.9112C303.928 59.4251 300.455 63.0846 296.164 63.0846Z" fill="currentColor"/>
                        <path d="M336.694 63.4976C333.407 64.0529 331.541 62.7429 330.766 61.9455C329.963 61.1196 329.374 59.7811 329.374 58.9125V46.8659H337.555V39.5896H329.374V31.1883H320.706V39.5896H314.778V46.8659H320.706V58.9125C320.706 65.6762 326.245 71.1726 333.063 71.1726C333.063 71.1726 336.234 71.2865 338.459 70.3752L336.694 63.4976Z" fill="currentColor"/>
                        <path d="M197.151 54.3986C197.122 58.2859 197.151 70.3609 197.151 70.3609V70.3752H188.425V39.6038H195.701L196.763 43.3915C198.285 41.042 200.208 39.5895 203.064 39.0769C206.25 38.5073 209.005 38.8633 211.474 40.1449C213.053 40.9708 214.301 42.2666 215.191 44.0038C215.205 43.9753 215.234 43.9326 215.263 43.9041C215.621 43.4202 215.981 42.9356 216.368 42.4802C217.904 40.686 219.913 39.5611 222.338 39.1054C224.749 38.664 227.06 38.7494 229.227 39.3902C231.71 40.1164 233.619 41.6543 234.882 43.9468C235.829 45.6555 236.374 47.6063 236.561 49.8847C236.647 50.9953 236.69 51.9921 236.69 52.9604V70.3894H228.007C228.007 70.3894 228.036 56.9474 228.007 52.5759C227.993 51.2374 227.677 50.0413 227.103 49.016C226.5 47.9623 225.654 47.3216 224.491 47.0368C223.056 46.6808 221.85 46.7377 220.587 47.2219C218.966 47.8342 217.875 49.073 217.243 50.9953C216.913 51.9921 216.755 53.117 216.755 54.3986V70.3609H208.273V52.818C208.273 51.7643 208.216 50.5254 207.728 49.3863C207.054 47.7772 205.834 46.9371 203.982 46.8232C202.59 46.7377 201.413 46.9371 200.38 47.4212C198.73 48.2044 197.955 49.5571 197.495 51.4795C197.28 52.405 197.151 53.3876 197.151 54.3986Z" fill="currentColor"/>
                        <path d="M7.35938 51.1778C7.35938 27.2804 26.9653 7.82797 51.0513 7.82797C75.1518 7.82797 94.7433 27.2804 94.7433 51.1778C94.7433 60.7968 91.6317 69.8727 85.7542 77.4907L63.8434 55.7515C64.3909 54.265 64.6646 52.7214 64.6646 51.1635C64.6646 43.717 58.5566 37.6569 51.0513 37.6569C43.546 37.6569 37.4381 43.717 37.4381 51.1635C37.4381 52.7214 37.7118 54.265 38.2592 55.7515L16.3484 77.4907C10.471 69.887 7.35938 60.7968 7.35938 51.1778Z" fill="currentColor"/>
                        <path d="M43.0418 61.4114L51.0513 49.0196L59.0608 61.3971L80.7699 82.9362C72.6596 90.4113 62.1292 94.5276 51.0513 94.5276C39.9735 94.5276 29.443 90.4113 21.3327 82.9362L43.0418 61.4114Z" fill="currentColor"/>
                    </Box>

                    <Text size={[2, 3, 4]} align="center" color="textOnCard">
                        {'Externen Inhalt von '}
                        <TextLink
                            href={externalHref}
                            {...getPropsByHref(externalHref)}
                            color="komootButtonOnCard"
                            decoration="inverted"
                        >
                            komoot
                        </TextLink>
                        {' anzeigen?'}
                    </Text>
                    <Button
                        variant="solid"
                        color="komootButtonOnCard"
                        solidTextColor="komootButtonTextOnCard"
                        onClick={handleConsent}
                    >
                        Anzeigen
                    </Button>
                </Fragment>
            )}
        </AspectRatio>
    );
};

MdxKomootEmbed.propTypes = {
    src: PropTypes.string.isRequired
};

MdxKomootEmbed.defaultProps = {
    aspectRatio: [
        3 / 4,
        4 / 4,
        4 / 3
    ]
};

export default MdxKomootEmbed;
