import React, {useEffect, useMemo, useRef, useState} from 'react';

// Utils
import {debounce} from 'lodash';
import {useForceRender} from '../../../hooks/useForceRender';

// Components
import {AspectRatio, Box, Button} from '../../../ui/components';
import Image from '../Image';
import {IconArrowBack, IconArrowForward} from '../../../ui/icons';
import {useGetImageBySrc} from '../../hooks';

const scrollContainerSx = {
    display: 'flex',
    width: '100%',
    height: '100%',
    overflow: 'scroll',
    scrollSnapType: 'x mandatory',
    /* Hide scrollbar */
    'MsOverflowStyle': 'none',  /* Internet Explorer 10+ */
    'scrollbarWidth': 'none',  /* Firefox */
    '::-webkit-scrollbar': {
        display: 'none' /* Chrome & Safari */
    }
};

const scrollItemSx = {
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: '100%',
    flexShrink: 0,
    justifyContent: 'center',
    scrollSnapAlign: 'start'
};

const controlOverlaySx = {
    position: 'absolute',
    display: 'flex',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    p: ['sm', 'sm', 'md'],
    pointerEvents: 'none'
};

const controlIndexItemSx = {
    width: '3',
    height: '3',
    bg: 'almostBlack',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'white',
    borderRadius: 'full',
    boxShadow: 'md'
};

const ImageCarousel = ({
    aspectRatio,
    images
}) => {
    const scrollContainer = useRef();

    const getImageBySrc = useGetImageBySrc();

    const imagesBySrc = useMemo(() => {
        return (Array.isArray(images) ? images : [])
            .map(image => {
                return {
                    ...image,
                    imageBySrc: getImageBySrc(image.src)
                };
            })
            .filter(image => image.imageBySrc);
    }, [images]);

    // Get state base on scroll position
    const getState = () => {
        const currX = scrollContainer.current ? scrollContainer.current.scrollLeft : 0;
        const totalX = scrollContainer.current ? scrollContainer.current.scrollWidth : 1;
        const itemX = scrollContainer.current ? scrollContainer.current.clientWidth : 1;

        const total = totalX / itemX;
        const curr = total - ((totalX - currX) / itemX) + 1;

        const hasPrev = curr > 1;
        const hasNext = curr < total;

        return {
            prevX: hasPrev ? itemX * (curr - 2) : currX,
            currX,
            nextX: hasNext ? itemX * curr : currX,
            itemX,
            totalX,
            curr,
            total,
            hasPrev,
            hasNext
        };
    };

    // Read state
    const {curr, hasNext, hasPrev} = getState();

    // Rerender on scroll to read latest state based on scroll position
    const forceRender = useForceRender();

    useEffect(() => {
        const scrollContainerElement = scrollContainer.current;

        if (scrollContainerElement) {
            const handleScroll = debounce(() => {
                forceRender();
            }, 10);

            scrollContainerElement.addEventListener('scroll', handleScroll);

            return () => {
                scrollContainerElement.removeEventListener('scroll', handleScroll);
            };
        }

    }, [scrollContainer.current]);

    // Show prev/next control on hover
    const [showPrevAndNextControl, setShowPrevAndNextControl] = useState(false);

    const handleControlMouseOver = () => {
        setShowPrevAndNextControl(true);
    };
    const handleControlMouseOut = () => {
        setShowPrevAndNextControl(false);
    };

    // Handle prev/next action
    const handleGoTo = (to) => {
        const {itemX, totalX} = getState();

        const toX = Math.min(Math.max(0, itemX * (to - 1)), totalX);

        scrollContainer.current.scroll({
            left: toX,
            behavior: 'smooth'
        });
    };

    const handlePrev = (event) => {
        event?.preventDefault?.();

        handleGoTo(curr - 1);
    };

    const handleNext = (event) => {
        event?.preventDefault?.();

        handleGoTo(curr + 1);
    };

    if (!imagesBySrc?.length) {
        return null;
    }

    return (
        <AspectRatio
            ratio={aspectRatio}
            sx={{
                bg: 'card'
            }}
            onMouseOver={handleControlMouseOver}
            onMouseOut={handleControlMouseOut}
        >
            <Box
                ref={scrollContainer}
                sx={{...scrollContainerSx}}
            >
                {imagesBySrc.map((image, i) => (
                    <Box
                        key={`${image.src}-${i}`}
                        sx={{
                            ...scrollItemSx,
                            bg: image.imageBySrc.backgroundColor
                        }}
                    >
                        <Image
                            src={image.src}
                            alt={image.alt || ''}
                            objectFit="scale-down"
                            sx={{
                                maxWidth: '100%',
                                maxHeight: '100%'
                            }}
                        />
                    </Box>
                ))}
            </Box>
            {showPrevAndNextControl ? (
                <Box
                    sx={{
                        ...controlOverlaySx,
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    <Button
                        size={['md+', 'md+', 'lg+']}
                        variant="transparent"
                        pill={true}
                        icon={IconArrowBack}
                        onClick={handlePrev}
                        sx={{
                            visibility: hasPrev ? 'visible' : 'hidden',
                            pointerEvents: 'all'
                        }}
                    />
                    <Button
                        size={['md+', 'md+', 'lg+']}
                        variant="transparent"
                        pill={true}
                        icon={IconArrowForward}
                        onClick={handleNext}
                        sx={{
                            visibility: hasNext ? 'visible' : 'hidden',
                            pointerEvents: 'all'
                        }}
                    />
                </Box>
            ) : null}
            <Box
                sx={{
                    ...controlOverlaySx,
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'end',
                    gap: 'xs'
                }}
            >
                {imagesBySrc.map((image, i) => (
                    <Box
                        key={`ctrl-${image.src}-${i}`}
                        as="button"
                        sx={{
                            ...controlIndexItemSx,
                            width: (i + 1) === curr ? '5' : controlIndexItemSx.width,
                            pointerEvents: 'all',
                            transition: 'width 0.75s'
                        }}
                        onClick={() => handleGoTo(i + 1)}
                    />
                ))}
            </Box>
        </AspectRatio>
    );
};

ImageCarousel.defaultProps = {
    aspectRatio: 4 / 3
};

export default ImageCarousel;
